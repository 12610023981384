<template>
  <b-modal
    id="upgrade-modal"
    title="Upgrade your plan to continue"
    size="lg"
    centered
    scrollable
  >
    <template #modal-footer="{ close }">
      <div>
        <b-button variant="outline-secondary" @click="close">Cancel</b-button>
        <b-button class="ml-2" variant="info" @click="next">
          See Upgrade Options
        </b-button>
      </div>
    </template>
    <div>
      <div class="text">
        You need to be on the Pro or Premium plans to use the AI detection
        feature.
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push("/account");
      this.$bvModal.hide("upgrade-modal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
