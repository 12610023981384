<template>
  <div class="selectable-table-wrapper">
    <div v-if="$scopedSlots.selectAll" class="select-all-wrapper">
      <slot
        name="selectAll"
        v-bind:selectedAll="selectedAll"
        v-bind:selectAll="selectAll"
      />
    </div>

    <div class="selectable-table mt-3">
      <b-table
        class="table"
        ref="selectableTable"
        hover
        sticky-header
        selectable
        select-mode="multi"
        :busy="loading"
        :items="items"
        :fields="innerFields"
        @row-clicked="handleRowClicked"
      >
        <template #cell(select)="{ item, index, value }">
          <div class="d-flex align-items-center">
            <b-checkbox
              v-if="selectable"
              :checked="selectedIds.includes(item.id)"
              @change="handleSelectItem($event, index)"
            />
            <div>{{ value }}</div>
          </div>
        </template>

        <template #cell(icon)="data">
          <b-icon :icon="data.value.icon" :style="data.value.style" />
        </template>

        <template #cell(action)="data">
          <a v-if="data.value.link" :href="data.value.link" target="_blank">
            {{ data.value.label }}
            <b-icon class="icon-box-arrow-up-right" icon="box-arrow-up-right" />
          </a>
          <b-button
            v-if="data.value.action"
            size="sm"
            v-bind="data.value.props"
            @click="data.value.action(data.item)"
          >
            {{ data.value.label }}
          </b-button>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    fields: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    selectable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selectedIds: [],
    };
  },

  computed: {
    selectedAll() {
      return this.items.length === 0
        ? false
        : this.selectedIds.length === this.items.length;
    },

    innerFields() {
      if (!this.selectable) return this.fields;

      const [firstItem, ...restFields] = this.fields;

      return [{ ...firstItem, key: "select" }, ...restFields];
    },
  },

  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.selectedIds = this.value;
        this.clearSelected();

        if (this.value.length > 0) {
          this.value.forEach((id) => {
            const index = this.items.findIndex((item) => item.id === id);
            this.selectRow(index);
          });
        }
      },
    },
  },

  methods: {
    handleSelectItem(value, index) {
      value ? this.selectRowAndEmit(index) : this.unselectRowAndEmit(index);
    },

    handleRowClicked(item, index, event) {
      event.preventDefault();
      event.stopPropagation();

      const isSelected = this.selectedIds.includes(item.id);
      this.handleSelectItem(!isSelected, index);
    },

    selectAllRows() {
      this.$refs?.selectableTable?.selectAllRows();
    },

    selectAllRowsAndEmit() {
      this.selectAllRows();
      this.selectedIds = this.items.map((s) => s.id);
      this.emitSelectedIds();
    },

    clearSelected() {
      this.$refs?.selectableTable?.clearSelected();
    },

    clearSelectedAndEmit() {
      this.clearSelected();
      this.selectedIds = [];
      this.emitSelectedIds();
    },

    emitSelectedIds() {
      this.$emit("input", this.selectedIds);
    },

    selectRow(index) {
      this.$refs?.selectableTable?.selectRow(index);
    },

    selectRowAndEmit(index) {
      this.selectRow(index);
      this.selectedIds.push(this.items[index].id);
      this.emitSelectedIds();
    },

    unselectRow(index) {
      this.$refs?.selectableTable?.unselectRow(index);
    },

    unselectRowAndEmit(index) {
      this.unselectRow(index);
      const itemId = this.items[index].id;
      this.selectedIds = this.selectedIds.filter((id) => id !== itemId);
      this.emitSelectedIds();
    },

    selectAll(value) {
      if (!value) {
        this.clearSelectedAndEmit();
      } else {
        this.selectAllRowsAndEmit();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-all-wrapper {
  width: 100%;
  padding: 15px 12px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* override bootstrap styles */
.custom-checkbox {
  cursor: pointer;
}

.selectable-table {
  &::v-deep {
    .table {
      border-bottom: 1px solid #dee2e6;
    }

    .table tr td {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .table.b-table > tbody > .table-active > td {
      background-color: #ffffff;
    }
  }
}

.icon-box-arrow-up-right {
  margin-bottom: 1px;
  margin-left: 1px;
}
</style>
