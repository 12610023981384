const version = process.env.VUE_APP_VERSION;
import { LOCAL_STORAGE_FEATURE_KEY } from "@/constants";

export default (vm) => {
  const localStorageVersion = localStorage.getItem(LOCAL_STORAGE_FEATURE_KEY);

  if (
    vm.$route?.meta?.requiresAuth &&
    vm.$store.state.loggedIn &&
    localStorageVersion !== version
  ) {
    vm.$bvModal.show("new-features-modal");
  }
};
