<script>
export default {
  name: "SubscriptionCancelConfirmModal",
};
</script>

<template>
  <b-modal
    id="subscription_cancel_confirm_modal"
    title="Are you sure you want to cancel your plan?"
    size="lg"
    centered
    hide-footer
    no-stacking
  >
    <p>We suggest pausing your plan instead.</p>

    <h6>Benefits of pausing your plan:</h6>

    <ul>
      <li>
        <b>💰 Save Costs:</b> Your next bill will be credited with any remaining
        days on your plan.
      </li>
      <li>
        <b>🛟 Keep your data:</b>
        Do not lose access to access your stored information, class details,
        Custom Rubrics, and graded essays.
      </li>
      <li><b>🙂 Flexibility:</b> You can pause for 1, 2, 3 or 4 months.</li>
    </ul>

    <p>
      If you decide to cancel your plan, you'll lose access to your stored
      information, class details, Custom Rubrics, and graded essays.
    </p>

    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-info"
        v-b-modal.subscription_cancel_upgrade_modal
      >
        Cancel subscription
      </b-button>
      <b-button class="ml-3" variant="info" v-b-modal.subscription_pause_modal>
        Pause subscription
      </b-button>
    </div>
  </b-modal>
</template>
