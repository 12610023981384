<script>
import { getClass } from "@/api";
import Breadcrumbs from "../components/global/breadcrumbs.vue";
import ClassForm from "@/components/classes/class_form.vue";

export default {
  name: "EditClassView",
  components: {
    Breadcrumbs,
    ClassForm,
  },
  data() {
    return {
      loading: true,
      error: "",
      classData: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Classes",
          href: "/classes",
        },
        {
          text: this.classData?.name || "View Class",
          href: `/class/${this.classData.id}`,
        },
        {
          text: "Edit",
          active: true,
        },
      ];
    },
  },
  async mounted() {
    try {
      const { data } = await getClass(this.$route.params.id);

      this.classData = data.classData;

      this.loading = false;
      this.error = "";
    } catch (error) {
      this.loading = false;
      this.error = error.response?.data?.error;
    }
  },
};
</script>

<template>
  <b-container>
    <section>
      <b-row v-if="loading || error">
        <b-col>
          <b-spinner v-if="loading"></b-spinner>
          <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
        </b-col>
      </b-row>
      <template v-else>
        <breadcrumbs :items="breadcrumbs" />
        <b-row>
          <b-col lg="6">
            <h1>Edit class</h1>
          </b-col>
        </b-row>
        <class-form
          editing
          :classId="classData.id"
          :className="classData.name"
          :classDescription="classData.description"
          :classSize="classData.size"
          button-text="Save changes"
        />
      </template>
    </section>
  </b-container>
</template>
