import Vue from "vue";
import Hotjar from "vue-hotjar";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "./assets/main.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(Hotjar, {
  id: "4982392",
  isProduction: process.env.NODE_ENV === "production",
  snippetVersion: 6,
});

Vue.config.productionTip = false;

export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
