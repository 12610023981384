<template>
  <div v-if="showComponent">
    <b-button
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="collapse-4"
      @click="visible = !visible"
      variant="link"
      >More assignment options</b-button
    >
    <b-collapse id="collapse-4" v-model="visible" class="mt-2">
      <div class="ml-3">
        <upload-content-area
          label="Sources (Text-based dependency analysis)"
          description="These will be used to help AI grade the essays that are
        uploaded to this assignment."
          value="text"
          :rows="4"
          @input-files="changeSourcesFiles"
          @input-text="changeSources"
        />

        <eg-form-group
          label="Assignment instructions"
          description="These will be used to help AI grade the essays that are uploaded to this assignment."
        >
          <b-form-textarea
            @input="changeText"
            placeholder="Paste the instructions here"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
        </eg-form-group>
      </div>
    </b-collapse>
  </div>
  <div v-else class="hided-by-feature-flag d-none"></div>
</template>

<script>
import { extractFileData } from "@/api";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import UploadContentArea from "./upload_content_area.vue";
import { getWordsCountOfAllText } from "@/components/rubrics/utils";

const MAX_INSTRUCTION_COUNT_WORDS = 10000;

export default {
  components: {
    EgFormGroup,
    UploadContentArea,
  },

  data() {
    return {
      showComponent: true,
      visible: false,
      tda: {
        sources: "",
        instructions: "",
      },
    };
  },

  created() {
    const featureFlags = this.$store.state.featureFlags;

    if (!featureFlags["TDAInstructions"]) {
      this.showComponent = false;
    }
  },

  methods: {
    async changeSourcesFiles(files) {
      if (files.length === 0) {
        return;
      }

      let fullSources = "";
      const formData = new FormData();

      if (files.length === 1) {
        formData.append("file", files[0]);
      }

      if (files.length > 1) {
        files.forEach((file) => {
          formData.append("bulkFiles", file);
        });
      }

      const { data } = await extractFileData(formData);
      console.log(data);

      // For bulk files we should merge all essays to one string with format
      /*
      Filename: essayName1
      Filecontents1

      ----

      Filename: essayName2
      Filecontents2
      */

      if (files.length === 1) {
        fullSources = data;
      }

      if (files.length > 1) {
        fullSources = data.essays
          .map((essay) => {
            return `Filename: ${essay.name}
            ${essay.essayContents}

            ----
            `;
          })
          .join("");
      }

      this.changeSources(fullSources);
    },

    changeSources(value) {
      this.tda.sources = value;
      this.$emit("input", this.tda);
    },

    changeText(value) {
      if (getWordsCountOfAllText(value) > MAX_INSTRUCTION_COUNT_WORDS) {
        return this.$bvToast.toast(
          `The length of the instructions cannot exceed ${MAX_INSTRUCTION_COUNT_WORDS} words.`,
          {
            title: "Error",
            variant: "danger",
          }
        );
      }

      this.tda.instructions = value;
      this.$emit("input", this.tda);
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  padding: 0;

  &.btn-link:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
    box-shadow: none;
  }

  &::before {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: -1px;
    vertical-align: 5px;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }

  &.collapsed::before {
    transform: rotate(180deg);
    margin-bottom: -2px;
  }
}
</style>
