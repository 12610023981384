<script>
import subscriptionCancelConfirmModal from "./subscription_cancel_confirm_modal.vue";
import subscriptionCancelSuccessModal from "./subscription_cancel_success_modal.vue";
import subscriptionCancelUpgradeModal from "./subscription_cancel_upgrade_modal.vue";
import subscriptionCancelModal from "./subscription_cancel_modal.vue";
import subscriptionPauseModal from "./subscription_pause_modal.vue";

export default {
  components: {
    subscriptionCancelUpgradeModal,
    subscriptionCancelModal,
    subscriptionCancelConfirmModal,
    subscriptionCancelSuccessModal,
    subscriptionPauseModal,
  },
  name: "SubscriptionPauseCancelSection",
  props: {
    username: {
      type: String,
      required: true,
    },
    scheduledToCancel: {
      type: Boolean,
      required: true,
    },
    isYearlyPlan: {
      type: Boolean,
      required: true,
    },
    subscriptionLoading: {
      type: Boolean,
      required: true,
    },
    isLitePlan: {
      type: Boolean,
      required: true,
    },
    isDiscounted: {
      type: Boolean,
      required: true,
    },
    cancelAtDate: {
      type: String,
      required: true,
    },
    cancelSubscription: {
      type: Function,
      required: true,
    },
    pauseSubscription: {
      type: Function,
      required: true,
    },
    updateToLitePlan: {
      type: Function,
      required: true,
    },
    applyCoupon: {
      type: Function,
      required: true,
    },
  },

  methods: {
    handleCancelButtonClick() {
      this.$bvModal.show(
        this.isYearlyPlan || this.isDiscounted
          ? "subscription_cancel_modal"
          : "subscription_cancel_confirm_modal"
      );
    },
  },
};
</script>

<template>
  <div class="mt-5">
    <h1 class="mb-4">{{ isYearlyPlan ? "Cancel " : "Pause " }}subscription</h1>
    <hr />

    <b-spinner v-if="subscriptionLoading"></b-spinner>

    <div v-else>
      <div v-if="!scheduledToCancel">
        <p class="mb-4">
          You can {{ isYearlyPlan ? "" : "pause or " }} cancel your subscription
          below.
        </p>

        <div v-if="!isYearlyPlan">
          <b-button variant="info" v-b-modal.subscription_pause_modal>
            Pause subscription
          </b-button>
        </div>

        <div class="mt-3">
          <b-button
            class="px-0"
            variant="link"
            @click="handleCancelButtonClick"
          >
            Cancel subscription
          </b-button>
        </div>

        <subscription-cancel-confirm-modal />
        <subscription-cancel-modal :cancelSubscription="cancelSubscription" />
        <subscription-pause-modal
          :pauseSubscription="pauseSubscription"
          :username="username"
        />
        <subscription-cancel-upgrade-modal
          :updateToLitePlan="updateToLitePlan"
          :applyCoupon="applyCoupon"
          :isLitePlan="isLitePlan"
        />
        <subscription-cancel-success-modal :isLitePlan="isLitePlan" />
      </div>

      <p v-else>
        You have successfully canceled your subscription. All features will
        still be available until <b>{{ cancelAtDate }}</b
        >. We hate to see you go but we understand EssayGrader might not be the
        right fit for you at this time. Please consider giving us some
        <a href="https://forms.gle/SZ7n3khm5iSRbMii8" target="_blank">
          feedback
        </a>
        so we can make EssayGrader better.
      </p>
    </div>
  </div>
</template>
