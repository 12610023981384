<script>
import ForgotPasswordForm from "../components/auth/forgot_password_form.vue";

export default {
  name: "ForgotPasswordView",
  components: {
    ForgotPasswordForm,
  },
};
</script>

<template>
  <b-container>
    <section>
      <b-row>
        <b-col>
          <a href="/"
            ><img src="../assets/auth-logo.png" alt="EssayGrader Logo"
          /></a>
          <hr />
          <h1 class="mb-4 purple-text">Forgot Your Password?</h1>
          <p class="mb-3">
            Enter your email address and we will send you a link to reset your
            password.
          </p>

          <forgot-password-form />
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
