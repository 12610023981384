import { render, staticRenderFns } from "./select_import.vue?vue&type=template&id=6ad9c634&scoped=true&"
import script from "./select_import.vue?vue&type=script&lang=js&"
export * from "./select_import.vue?vue&type=script&lang=js&"
import style0 from "./select_import.vue?vue&type=style&index=0&id=6ad9c634&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ad9c634",
  null
  
)

export default component.exports