<template>
  <div
    class="auth-box-wrapper d-flex flex-column align-items-center justify-content-center"
  >
    <b-alert class="alert" :show="!!error" variant="danger" dismissible>{{
      error
    }}</b-alert>
    <div class="auth-box">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <img
          class="logo"
          src="../../assets/auth-logo.png"
          alt="EssayGrader Logo"
        />

        <h1 v-if="title" class="title my-4 py-2">{{ title }}</h1>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },

    error: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.auth-box-wrapper {
  height: 100vh;
}

.alert,
.auth-box {
  max-width: 547px;
  width: 100%;
}

.auth-box {
  padding: 30px 0;
  border-radius: 4px;
  border: 1px solid #e9ecef;

  .logo {
    width: 162px;
  }

  .title {
    font-family: "Roboto", "Rubik", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    margin: 30px 0;
  }
}
</style>
