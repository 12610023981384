<template>
  <div v-if="loading">
    <b-skeleton width="25%"></b-skeleton>
    <b-skeleton type="input"></b-skeleton>
  </div>
  <div v-else class="select-rubric">
    <eg-form-group
      v-if="rubrics.length > 0"
      label="Rubric"
      description="The same rubric must be used to grade all assignments selected in the previous step."
      required
    >
      <rubric-select
        :rubrics="rubrics"
        :value="selectedRubric"
        @input="selectRubric"
      />
    </eg-form-group>
    <div v-else class="rubric-form-wrapper">
      <div class="not-have-text text-center mb-4">
        You don’t have any rubrics yet.
      </div>
      <div class="radio-buttons d-flex justify-content-center">
        <b-button
          :variant="
            type === 'default' ? 'outline-primary' : 'outline-secondary'
          "
          class="mr-2"
          @click="type = 'default'"
        >
          Use a default rubric
        </b-button>
        <b-button
          :variant="
            type === 'default' ? 'outline-secondary' : 'outline-primary'
          "
          class="ml-2"
          @click="type = 'create'"
        >
          Create a custom rubric
        </b-button>
      </div>
      <div class="wrapper mt-4">
        <div v-if="type === 'default'">
          <rubric-default
            horizontal
            :gradeLevel="selectedDefaultRubric.gradeLevel"
            :essayType="selectedDefaultRubric.essayType"
            @input="$emit('input-default-rubric', $event)"
          />
        </div>
        <rubric-form v-else no-buttons ref="rubricForm" />
      </div>
    </div>
  </div>
</template>

<script>
import { getRubrics, createRubric } from "@/api";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import RubricSelect from "@/components/rubrics/rubric_select";
import RubricForm from "@/components/rubrics/rubric_form.vue";
import RubricDefault from "@/components/rubrics/rubric_default.vue";
import errorHandler from "./errorHandler";

export default {
  components: {
    RubricSelect,
    EgFormGroup,
    RubricForm,
    RubricDefault,
  },

  props: {
    value: {
      type: Object,
    },

    selectedDefaultRubric: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      type: "default",
      loading: true,
      selectedRubric: null,
      rubrics: [],
    };
  },

  methods: {
    submit() {
      if (this.type === "default") {
        return true;
      }

      const rubricData = this.$refs.rubricForm.onSubmit();

      if (!rubricData) return false;

      return this.submittedHandler(rubricData);
    },

    async submittedHandler(rubricData) {
      try {
        await this.createRubric(rubricData);
        await this.init();

        return true;
      } catch (error) {
        this.$bvToast.toast(
          "There was a problem saving your rubric. Please try again.",
          {
            title: "Error",
            variant: "danger",
          }
        );
      }
    },

    async createRubric(rubricData) {
      try {
        await createRubric(rubricData);
      } catch (error) {
        this.$bvToast.toast(
          "There was a problem saving your rubric. Please try again.",
          {
            title: "Error",
            variant: "danger",
          }
        );
      }
    },

    inputDefaultHandler({ type, value }) {
      this.$emit("input-default-rubric", { type, value });
    },

    selectRubric(rubricData) {
      this.selectedRubric = rubricData;
      this.$emit("input", rubricData);
    },

    async init() {
      try {
        this.loading = true;
        const { data } = await getRubrics();
        this.rubrics = data;

        let selectedRubricData = (this.rubrics || 0)[0];

        if (this.value?.id) {
          const rubricData = this.rubrics.find((c) => c.id === this.value.id);

          if (rubricData) {
            selectedRubricData = rubricData;
          }
        }

        selectedRubricData && this.selectRubric(selectedRubricData);
        this.loading = false;
        this.$emit("loaded");
      } catch (error) {
        this.loading = false;
        this.$emit("loaded");

        errorHandler({
          vm: this,
          error,
          message:
            "There was a problem processing your selection. Please try again.",
        });
      }
    },
  },

  created() {
    this.init();
  },
};
</script>

<style scoped>
.not-have-text {
  font-size: 18px;
  font-weight: 400;
}
.btn {
  color: #212529;
}

.btn:focus {
  box-shadow: none;
}

.btn-outline-primary {
  background-color: rgba(0, 123, 255, 0.1);
}
</style>
