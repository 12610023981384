<template>
  <div class="uppy-wrapper">
    <div ref="dashboardContainer"></div>
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import GoogleDrive from "@uppy/google-drive";
import OneDrive from "@uppy/onedrive";
import Box from "@uppy/box";
import Dropbox from "@uppy/dropbox";
import UppyDashboardUploader from "./uppy_dashboard_uploader";
import { COMPANION_URL, COMPANION_ALLOWED_HOSTS } from "@uppy/transloadit";
import Transloadit from "@uppy/transloadit";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },

    maxFileSizeInBytes: {
      type: Number,
      default: 10000000,
    },

    maxNumberOfFiles: {
      type: Number,
      default: 1,
    },

    acceptFiles: {
      type: Array,
      default: () => [
        ".txt",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ],
    },
  },

  data() {
    return {
      uppy: null,
      payload: null,
      disabled: true,
    };
  },

  mounted() {
    this.payload = this.value;
    this.initUppy();
  },

  methods: {
    initUppy() {
      this.uppy = new Uppy({
        debug: process.env.NODE_ENV === "development",
        autoProceed: false,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: this.maxNumberOfFiles,
          allowedFileTypes: this.acceptFiles,
        },
      })
        .use(Dashboard, {
          hideUploadButton: true,
          inline: true,
          height: "100%",
          width: "100%",
          target: this.$refs.dashboardContainer,
          replaceTargetContent: true,
          showProgressDetails: true,
          browserBackButtonClose: true,
        })
        .use(Transloadit, {
          waitForEncoding: true,
          importFromUploadURLs: true,
          assemblyOptions: {
            params: {
              auth: { key: process.env.VUE_APP_TRANSLOADIT_KEY },
              template_id: process.env.VUE_APP_TRANSLOADIT_TEMPLATE_ID,
            },
          },
        });

      const companionUrl = COMPANION_URL;
      const companionAllowedHosts = COMPANION_ALLOWED_HOSTS;

      this.uppy.use(GoogleDrive, {
        companionUrl,
        companionAllowedHosts,
      });

      this.uppy.use(OneDrive, {
        companionUrl,
        companionAllowedHosts,
      });

      this.uppy.use(Box, {
        companionUrl,
        companionAllowedHosts,
      });

      this.uppy.use(Dropbox, {
        companionUrl,
        companionAllowedHosts,
      });

      const uploader = new UppyDashboardUploader(this.uppy, {
        isTos: true,
        // endpoint: `${process.env.VUE_APP_EG_API_URL}/upload/uppy/extract`,
        // withCredentials: true,
        // headers: {
        //   authorization: `Bearer ${this.$store.state.token}`,
        // },
      });

      this.uppy.addUploader(() => uploader.upload());

      this.uppy.on("complete", (event) => {
        if (event.successful.length) {
          this.payload = [
            ...(event.successful
              .filter((item) => !item.isRemote)
              .map((item) => ({
                name: item?.name,
                content: item?.response?.body?.content,
              })) || []),
            ...(event.transloadit?.[0]?.uploads
              ?.filter((item) => item.is_tus_file)
              ?.map((item) => ({
                name: item?.name,
                fileUrl: item?.ssl_url,
              })) || []),
          ];
          this.$emit("input", this.payload);

          this.disabled = false;
        }
      });
    },

    upload() {
      return this.uppy.upload();
    },

    resetUploader() {
      this.uppy.reset();
      this.disabled = true;

      return this;
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.image-container > img {
  width: inherit;
  height: inherit;
}

.uppy-wrapper::v-deep {
  .uppy-Dashboard-inner {
    border: none;
    background-color: rgba(111, 66, 193, 0.1);
  }

  .uppy-Dashboard-AddFiles {
    border-color: #6f42c1;
    height: 100%;
    margin: 0;
    border-radius: 5px;
  }

  .uppy-Dashboard-AddFiles-title {
    font-size: 16px;
    color: #212529;
  }

  .uppy-DashboardTab-name {
    font-size: 14px;
    color: #212529;
  }

  .uppy-DashboardContent-back:focus,
  .uppy-DashboardContent-save:focus,
  .uppy-DashboardTab-btn:hover {
    background-color: rgba(111, 66, 193, 0.2);
  }

  .uppy-Provider-btn-google,
  .uppy-c-btn-primary {
    background-color: #6f42c1;
  }

  .uppy-DashboardTab-iconMyDevice,
  .uppy-DashboardContent-addMore,
  .uppy-ProviderBrowser-userLogout,
  .uppy-DashboardContent-back {
    color: #6f42c1;
  }

  .uppy-ProviderBrowser-viewType--list
    .uppy-ProviderBrowserItem--is-checked
    .uppy-ProviderBrowserItem-checkbox,
  .uppy-ProviderBrowser-viewType--list
    .uppy-ProviderBrowserItem--is-partial
    .uppy-ProviderBrowserItem-checkbox {
    background-color: #6f42c1;
    border-color: #6f42c1;
  }
}
</style>
