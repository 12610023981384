<template>
  <b-card class="mt-5 px-2 px-md-4 py-1">
    <b-row>
      <b-col cols="12" md="6">
        <img
          src="../../../assets/icons/connect-1.svg"
          alt="Connect icon 1"
          class="custom-img"
        />
      </b-col>
      <b-col cols="12" md="6">
        <div>
          <img
            src="../../../assets/icons/connect-2.svg"
            alt="Connect icon 2"
            class="custom-img"
          />
        </div>
      </b-col>
    </b-row>
    <b-card-text class="mt-4">
      <h2 class="mb-4">Connecting to Google Classroom</h2>
      When you connect to Google Classroom, EssayGrader will be able to import
      student assignments so they can be graded without manually uploading them.
    </b-card-text>
    <b-card-text>
      <strong>Don't forget!</strong>
      <ul>
        <li>
          Allow EssayGrader to access all Google Classroom assignment data by
          selecting "Select all".
        </li>
        <li>
          We will not make any changes to Google Classroom without your
          permission.
        </li>
        <li>
          The use and transfer to any other app of information received from
          Google APIs will adhere to
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            target="_blank"
          >
            Google API Services User Data Policy</a
          >, including the Limited Use requirements.
        </li>
      </ul>
    </b-card-text>
    <div class="text-center mt-2">
      <google-button
        type="permissions"
        :scope="permissionScope"
        @done="$emit('input', $event)"
      >
        Continue with Google
      </google-button>
    </div>
  </b-card>
</template>

<script>
import { CLASSROOM_SCOPE } from "@/constants";
import GoogleButton from "@/components/auth/google_button.vue";

export default {
  name: "GoogleClassroomConnect",

  components: {
    GoogleButton,
  },

  props: {
    value: {
      type: String,
    },
  },

  data() {
    return {
      permissionScope: CLASSROOM_SCOPE,
    };
  },
};
</script>

<style scoped>
h2 {
  font-size: 30px;
  font-weight: 900;
  line-height: 119%;
  font-family: "Roboto", sans-serif;
}

.card-text {
  font-size: 16px;
  line-height: 24px;

  strong {
    font-size: 17px;
  }
}

.custom-img {
  max-width: 100%;
  width: 100%;
  max-height: 150px;
  object-fit: none;
}
</style>
