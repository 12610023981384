<template>
  <div class="global-modals-wrapper">
    <new-feature-modal />
    <upgrade-modal />
  </div>
</template>

<script>
import NewFeatureModal from "./new_feature_modal.vue";
import UpgradeModal from "./upgrade_modal.vue";

export default {
  components: {
    NewFeatureModal,
    UpgradeModal,
  },
};
</script>
