import { render, staticRenderFns } from "./essay_language_select.vue?vue&type=template&id=23674a42&"
import script from "./essay_language_select.vue?vue&type=script&lang=js&"
export * from "./essay_language_select.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports