<template>
  <b-form-group
    :description="descriptionPosition === 'top' ? description : ''"
    :label-for="labelFor"
  >
    <template #label v-if="label">
      {{ label }}
      <span v-if="required" class="red-text">*</span>
      <p
        v-if="descriptionPosition === 'bottom'"
        class="text-muted small mb-2 mt-0"
      >
        <slot v-if="$slots.description" name="description" />
        <template v-else>
          {{ description }}
        </template>
      </p>
    </template>
    <slot />
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },

    labelFor: {
      type: String,
    },

    description: {
      type: String,
      default: "",
    },

    descriptionPosition: {
      type: String,
      default: "bottom", // bottom, top
    },

    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
