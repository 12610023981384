<script>
import { extractFileData } from "@/api";

export default {
  name: "AiDetectorForm",
  data() {
    return {
      essayContents: "",
      essayFile: null,
      loading: false,
      error: "",
      showFileUpload: true,
      showPlainText: false,
      fileVariant: "secondary",
      plainTextVariant: "outline-secondary",
    };
  },
  computed: {
    canSubmit() {
      return this.essayContents || this.essayFile;
    },
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();

      if (this.canSubmit) {
        if (this.essayFile) {
          try {
            this.loading = true;

            const formData = new FormData();

            formData.append("file", this.essayFile);

            const { data } = await extractFileData(formData);

            const payload = {
              essayContents: data,
              user: this.$store.state.user,
            };

            this.loading = false;
            this.error = "";

            this.$emit("essaySubmitted", payload);
          } catch (error) {
            this.loading = false;
            this.error = error.response?.data?.error;
          }
        } else {
          const payload = {
            essayContents: this.essayContents,
            user: this.$store.state.user,
          };

          this.$emit("essaySubmitted", payload);
        }
      } else {
        this.error =
          "Error: You are missing form data, ensure that you have provided the essay contents.";
      }
    },
    handleFileClick() {
      this.fileVariant = "secondary";
      this.plainTextVariant = "outline-secondary";
      this.showPlainText = false;
      this.showFileUpload = true;
    },
    handlePlainTextClick() {
      this.fileVariant = "outline-secondary";
      this.plainTextVariant = "secondary";
      this.showPlainText = true;
      this.showFileUpload = false;
    },
  },
  watch: {
    // this watcher is used to ensure a user
    // cannot upload a file in form, switch to text
    // and upload text content and make call to API
    // only one type of data can be sent up
    uploadType(type) {
      if (type === "text") {
        this.essayFile = null;
      } else {
        this.essayContents = "";
      }
    },
  },
};
</script>

<template>
  <div>
    <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
    <b-button-group>
      <b-button :variant="fileVariant" @click="handleFileClick">File</b-button>
      <b-button :variant="plainTextVariant" @click="handlePlainTextClick">
        Plain Text
      </b-button>
    </b-button-group>
    <b-form @submit="onSubmit">
      <b-form-group
        v-if="showFileUpload"
        id="upload-group"
        description="Supported file types are .docx,.txt,.pdf"
      >
        <b-form-file
          id="upload"
          v-model="essayFile"
          placeholder="Choose file"
          accept=".pdf,.txt,.docx"
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>

      <b-form-group v-if="showPlainText" id="textarea-group">
        <b-form-textarea
          id="textarea"
          v-model="essayContents"
          placeholder="Paste the contents of the essay"
          rows="12"
          max-rows="24"
        ></b-form-textarea>
      </b-form-group>
      <div class="text-right">
        <b-button
          type="submit"
          variant="info"
          :disabled="loading"
          class="ai-detect-btn"
        >
          Detect
        </b-button>
      </div>

      <p v-if="loading">Parsing pdf text...</p>
    </b-form>
  </div>
</template>
