<template>
  <div class="essays-table-filters">
    <b-form-group
      v-if="availableFilters.includes('name')"
      label="Essay name"
      label-for="essay-search"
      class="search-input-wrapper"
    >
      <b-form-input
        class="pr-4"
        size="sm"
        id="essay-search"
        type="text"
        placeholder="Filter by name"
        ref="searchInput"
        v-model="searchText"
        @input="changeSearch($event)"
      ></b-form-input>
      <b-icon
        v-if="searchText"
        class="clear-input"
        icon="x-circle-fill"
        style="color: #adb5bd"
        @click="clearSearch"
      />
    </b-form-group>
    <b-form-group
      v-if="availableFilters.includes('class')"
      label="Class"
      label-for="essay-class-search"
      class="ml-md-3"
    >
      <div>
        <eg-dropdown
          size="sm"
          :text="selectedClass ? selectedClass.name : 'Select class'"
        >
          <b-dropdown-text v-if="classes.length === 0">
            No classes have been created yet.
          </b-dropdown-text>
          <b-dropdown-item-button
            v-for="classData in classes"
            :key="classData.id"
            class="dropdown-item-wrapper"
            @click="() => selectClass(classData)"
          >
            {{ classData.name }}
          </b-dropdown-item-button>
        </eg-dropdown>
      </div>
    </b-form-group>

    <b-form-group
      v-if="availableFilters.includes('rubric')"
      label="Rubric"
      label-for="essay-rubric-search"
      class="ml-md-3"
    >
      <rubric-select
        size="sm"
        :value="selectedRubric"
        :rubrics="rubrics"
        @input="selectRubric"
      />
    </b-form-group>

    <assignment-select
      v-if="availableFilters.includes('assignment')"
      class="ml-md-3"
      @input="selectAssignment"
      @input:assignments="assignmentsData = $event"
    />

    <b-button
      v-if="assignmentsData.length > 0"
      variant="outline-info"
      class="export-to-gc d-flex align-items-center ml-0 ml-md-2"
      @click="openExportToClassroomModal"
    >
      <b-icon class="mr-1" icon="box-arrow-up" />
      <span>Export to Google Classroom</span>
    </b-button>

    <b-button
      v-if="isFiltered"
      variant="link"
      class="clear-all px-1 ml-2"
      @click="clearAll"
    >
      Clear all
    </b-button>
  </div>
</template>

<script>
import { getClasses, getRubrics } from "@/api";
import debounce from "@/utils/debounce";
import EgDropdown from "@/components/global/eg_dropdown";
import RubricSelect from "@/components/rubrics/rubric_select";
import AssignmentSelect from "@/components/essay/assignment_select";

export default {
  props: {
    availableFilters: {
      type: Array,
      default: () => ["name", "class", "rubric", "assignment"],
    },
  },
  components: {
    EgDropdown,
    RubricSelect,
    AssignmentSelect,
  },

  data() {
    return {
      error: "",
      oldSearchText: "",
      searchText: "",
      selectedAssignment: null,
      selectedClass: null,
      selectedRubric: null,
      assignmentsData: [],
      classes: [],
      rubrics: [],
    };
  },

  computed: {
    isFiltered() {
      return (
        this.oldSearchText ||
        this.selectedClass ||
        this.selectedRubric ||
        this.selectedAssignment
      );
    },

    groupedRubrics() {
      return this.rubrics.reduce((acc, rubric) => {
        if (!acc[rubric.type]) {
          acc[rubric.type] = [];
        }
        acc[rubric.type].push(rubric);
        return acc;
      }, {});
    },
  },

  methods: {
    selectClass(classData) {
      this.selectedClass = classData;
      this.$emit("filter-changed", { filters: { ClassId: classData.id } });
    },

    selectRubric(rubricData) {
      this.selectedRubric = rubricData;
      this.$emit("filter-changed", { filters: { RubricId: rubricData.id } });
    },

    selectAssignment(assignmentData) {
      this.selectedAssignment = assignmentData;
      this.$emit("filter-changed", {
        filters: { AssignmentId: assignmentData.id },
      });
    },

    changeSearch: debounce(function (value = "") {
      if (
        value.length >= 2 ||
        (this.oldSearchText.length >= 2 && value.length === 0)
      ) {
        this.oldSearchText = value;
        this.$emit("filter-changed", { filters: { name: value } });
      }
    }, 500),

    clearAll() {
      this.clearSearch();
      this.selectedClass = null;
      this.selectedRubric = null;
      this.selectedAssignment = null;
      this.$emit("filter-changed", { filters: {} });
    },

    clearSearch() {
      this.searchText = "";
      this.changeSearch("");
      this.$refs.searchInput.focus();
    },

    async fetchClasses() {
      try {
        const { data } = await getClasses({ limit: 1000 });

        this.classes = data.classes;
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },

    async fetchRubrics() {
      try {
        const { data } = await getRubrics();
        this.rubrics = data;
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },

    openExportToClassroomModal() {
      this.$bvModal.show("export-to-classroom");
    },
  },

  created() {
    this.fetchClasses();
    this.fetchRubrics();
  },
};
</script>

<style lang="scss" scoped>
.essays-table-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: end;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }

  & > .form-group {
    flex: 0 1 100%;
    max-width: 100%;

    @media (min-width: 768px) {
      flex: 0 1 215px;
      max-width: 215px;
    }
  }
}

.search-input-wrapper {
  position: relative;

  .clear-input {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.dropdown-item-wrapper {
  &::v-deep {
    .dropdown-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.clear-all {
  color: #6c757d;
  flex: 0 1 75px;
  margin: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.export-to-gc {
  width: 220px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 1rem;
  flex-shrink: 0;

  svg {
    height: 14px;
    width: 14px;
    margin-bottom: 2px;
  }
}
</style>
