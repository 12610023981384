<script>
export default {
  name: "BreadCrumbs",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <b-row>
    <b-col>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </b-col>
  </b-row>
</template>
