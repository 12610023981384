<template>
  <auth-box title="Log in to EssayGrader" :error="error">
    <div class="px-5">
      <div class="text-center mb-4">
        <google-button class="mb-4 w-100" native @done="handleDone" />

        <div class="divider">
          <div class="or">or</div>
        </div>
      </div>

      <b-form @submit.prevent="onSubmit">
        <b-form-group
          id="email-group"
          label="Email Address"
          label-for="email-input"
        >
          <b-form-input
            id="email-input"
            v-model="email"
            type="email"
            placeholder="Enter email address"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="password-group"
          label="Password"
          label-for="password-input"
        >
          <b-form-input
            id="password-input"
            v-model="password"
            type="password"
            placeholder="Enter password"
            required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="info" class="login-btn w-100">
          Log in
        </b-button>
      </b-form>

      <p class="mt-4 text-center">
        <router-link to="/forgot-password">Forgot password?</router-link>
      </p>

      <div class="divider"></div>

      <p class="mt-3 mb-2 text-center">
        Don't have an account yet?
        <router-link to="/register">Register</router-link>
      </p>
    </div>
  </auth-box>
</template>

<script>
import { login } from "../../api";
import loginRedirectMixin from "./loginRedirectMixin";
import GoogleButton from "./google_button.vue";
import AuthBox from "./auth_box.vue";

export default {
  name: "LoginForm",

  components: {
    AuthBox,
    GoogleButton,
  },

  mixins: [
    // this.redirectToRoute
    loginRedirectMixin,
  ],

  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    async onSubmit() {
      const loginData = {
        email: this.email,
        password: this.password,
      };

      try {
        const { data } = await login(loginData);

        this.$store.dispatch("setUser", data.user);
        this.$store.dispatch("setToken", data.token);
        this.redirectToRoute(this, data.user);
        this.error = "";
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },

    handleDone(user) {
      this.redirectToRoute(this, user);
    },
  },
};
</script>
