import api from "@/api/api";

export const getEssaySummary = (payload) => {
  return api.post("/summarize", payload);
};

export const getAiDetectionReport = (payload) => {
  return api.post("/detector", payload);
};

export const getUsage = () => {
  return api.get("/usage");
};

export const getLanguages = () => {
  return api.get("/languages");
};

export const getAssignments = ({ limit }) => {
  return api.get(`/assignments?limit=${limit}`);
};

export const getFeatures = () => {
  return api.get("/webflow/features");
};

export const getBlogPostById = (blogPostId) => {
  return api.get(`/webflow//blog/${blogPostId}`);
};
