import capitalize from "@/utils/capitalize";

export const getIntensityDisplayText = (intensity) => {
  switch (intensity) {
    case "light":
      return "Easy";
    case "moderate":
      return "Normal";
    case "intense":
      return "Strict";
    default:
      return "Normal";
  }
};

export const formatDefaultType = (essay) => {
  let str = "";

  if (essay.gradeLevel) {
    str += capitalize(essay.gradeLevel);
  }

  if (essay.type) {
    str += `${str.length > 0 && " - "} ${capitalize(essay.type)} `;
  }

  return str;
};
