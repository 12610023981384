<template>
  <div class="select-submissions">
    <eg-selectable-table
      :value="selectedIds"
      :loading="loading"
      :items="submissions"
      :fields="fields"
      @input="onInput"
    >
      <template v-slot:selectAll="{ selectedAll, selectAll }">
        <b-checkbox
          :checked="selectedAll"
          @change="selectAll"
          variant="info"
          :indeterminate="
            selectedIds.length > 0 && selectedIds.length < submissions.length
          "
        >
          Select all submissions
        </b-checkbox>
      </template>
    </eg-selectable-table>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate";
import { getClassroomSubmissions } from "@/api";
import errorHandler from "./errorHandler";
import EgSelectableTable from "@/components/global/eg_selectable_table";

export default {
  components: {
    EgSelectableTable,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    classId: {
      type: String,
      required: true,
    },

    assignmentId: {
      type: String,
      required: true,
    },
  },

  inject: ["selectedGoogleAccountId"],

  data() {
    return {
      loading: true,
      selectedIds: [],
      submissions: [],
      fields: [
        {
          key: "student",
          label: "Student name",
          formatter: (value, key, item) => {
            return item.student.name.fullName;
          },
        },
        {
          key: "creationTime",
          label: "Submitted on",
          formatter: (value) => {
            return formatDate(value);
          },
        },
        {
          key: "updateTime",
          label: "Last updated",
          formatter: (value) => {
            return formatDate(value);
          },
        },
      ],
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    emitSelectedIds() {
      this.$emit("input", this.selectedIds);
    },

    onInput(value) {
      this.selectedIds = value;
      this.emitSelectedIds();
    },

    async init() {
      try {
        this.loading = true;
        const { data } = await getClassroomSubmissions(
          this.selectedGoogleAccountId,
          this.classId,
          this.assignmentId
        );
        this.submissions = data.submissions;
        this.loading = false;
        this.$emit("loaded");

        // NOTE: if value prop is empty, select all submissions
        if (this.value.length === 0) {
          this.selectedIds = this.submissions.map((s) => s.id);
          this.emitSelectedIds();

          return;
        }

        // NOTE: add only available submissions to selectedIds array,
        this.value.forEach((id) => {
          const index = this.submissions.findIndex((s) => s.id === id);

          if (index > -1) {
            this.selectedIds = [
              ...this.selectedIds,
              this.submissions[index].id,
            ];
          }
        });
        this.emitSelectedIds();
      } catch (error) {
        this.loading = false;
        this.$emit("loaded");

        errorHandler({
          vm: this,
          error,
          message:
            "There was a problem fetching your submissions. Please try again.",
        });
      }
    },
  },
};
</script>
