<template>
  <b-form-group v-if="assignmentsData.length > 0" label="Assignment">
    <div>
      <eg-dropdown
        size="sm"
        :text="
          selectedAssignment ? selectedAssignment.title : 'Select assignment'
        "
      >
        <b-dropdown-item-button
          v-for="assignment in assignmentsData"
          :key="assignment.id"
          class="dropdown-item-wrapper"
          @click="() => selectAssignment(assignment)"
        >
          <slot
            v-if="$scopedSlots.item"
            name="item"
            v-bind:assignment="assignment"
          ></slot>
          <template v-else>
            {{ assignment.title }}
          </template>
        </b-dropdown-item-button>
      </eg-dropdown>
    </div>
  </b-form-group>
</template>

<script>
import { getAssignments } from "@/api";
import EgDropdown from "@/components/global/eg_dropdown";

export default {
  data() {
    return {
      selectedAssignment: null,
      assignmentsData: [],
    };
  },
  components: {
    EgDropdown,
  },

  methods: {
    selectAssignment(assignmentData) {
      this.selectedAssignment = assignmentData;
      this.$emit("input", assignmentData);
    },

    async fetchAssignments() {
      try {
        const { data } = await getAssignments({ limit: 1000 });
        this.assignmentsData = data.rows;
        this.$emit("input:assignments", this.assignmentsData);
      } catch (error) {
        this.$bvToast.toast(error.response?.data?.error, {
          title: "Error fetching assignments",
          variant: "danger",
        });
      }
    },
  },

  created() {
    this.fetchAssignments();
  },
};
</script>
