import api from "@/api/api";

export const checkout = (payload) => {
  return api.post("/checkout", payload);
};

export const upgrade = (payload) => {
  return api.post("/upgrade", payload);
};

export const success = (payload) => {
  return api.post("/success", payload);
};

export const cancel = (payload) => {
  return api.put("/payments/subscription/cancel", payload);
};

export const pause = (payload) => {
  return api.put("/payments/subscription/pause", payload);
};

export const resume = () => {
  return api.put("/payments/subscription/resume");
};

export const unpause = async (vm) => {
  try {
    await resume();

    vm.$store.dispatch("setUser", {
      ...vm.$store.state.user,
      isPaused: false,
    });
    vm.$bvToast.toast("Your plan has been unpaused.", {
      title: "Success",
      variant: "success",
    });
  } catch (error) {
    vm.$bvToast.toast(error.message, {
      title: "Error",
      variant: "danger",
    });
  }
};

export const applyCoupon = (type) => {
  return api.put(`/payments/coupons/${type}`);
};

export const customerPortal = (payload) => {
  return api.post("/customer-portal", payload);
};

export const getStripeInfo = (stripeSubscriptionId) => {
  return api.get("/stripe-info", { params: { stripeSubscriptionId } });
};

export const getSubscriptionInfo = () => {
  return api.get("/subscription-info");
};
