<script>
import {
  getSingleRubric,
  updateSingleRubric,
  deleteSingleRubric,
} from "../api";
import Breadcrumbs from "../components/global/breadcrumbs.vue";
import RubricForm from "../components/rubrics/rubric_form.vue";

export default {
  name: "EditRubricView",
  components: {
    Breadcrumbs,
    RubricForm,
  },
  data() {
    return {
      loading: true,
      error: "",
      rubric: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Rubrics",
          href: "/rubrics",
        },
        {
          text: "Edit",
          active: true,
        },
      ];
    },
  },
  methods: {
    async updateRubric(rubricData) {
      try {
        this.loading = true;

        await updateSingleRubric(this.$route.params.id, rubricData);

        this.$router.push("/rubrics");
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    async deleteRubric() {
      try {
        this.loading = true;

        await deleteSingleRubric(this.$route.params.id);

        this.$router.push("/rubrics");
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },
  },
  async mounted() {
    try {
      const { data } = await getSingleRubric(this.$route.params.id);

      this.rubric = data;

      this.loading = false;
      this.error = "";
    } catch (error) {
      this.loading = false;
      this.error = error.response?.data?.error;
    }
  },
};
</script>

<template>
  <b-container>
    <section v-if="loading">
      <b-row>
        <b-col>
          <div class="text-center">
            <b-spinner class="mb-3"></b-spinner>
            <p>Processing request...</p>
          </div>
        </b-col>
      </b-row>
    </section>
    <section v-else>
      <breadcrumbs :items="breadcrumbs" />
      <b-row class="mb-3">
        <b-col v-if="error" cols="12">
          <b-alert variant="danger" class="mb-4" show>
            {{ error }}
          </b-alert>
        </b-col>
        <b-col lg="12">
          <h1 class="mb-0">Edit rubric</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <rubric-form
            :rubric="rubric"
            editing
            @rubricSubmitted="updateRubric"
            @delete="deleteRubric"
          />
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
