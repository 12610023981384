<script>
export default {
  name: "UsageBox",
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    remainder: {
      type: Number,
      required: true,
    },
  },
};
</script>

<template>
  <b-card class="usage-card text-center">
    <template #header></template>
    <div class="title mb-3">{{ title }}</div>
    <div class="mb-2">
      <span class="count">{{ count }}</span>
    </div>
    <p class="light-grey-text">
      <span class="font-rubik">{{ remainder }}</span>
      remaining submissions
    </p>
  </b-card>
</template>
