<script>
export default {
  name: "EmptyState",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
    },
    buttonPath: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <b-row class="text-center">
    <b-col>
      <slot></slot>
      <h1 :class="{ 'mt-5': true, 'mb-4': true, small }">{{ title }}</h1>
      <p :class="{ 'mb-4': true, small, 'text-secondary': true }">
        {{ description }}
      </p>
      <b-button v-if="buttonText && buttonPath" :to="buttonPath" variant="info">
        {{ buttonText }}
      </b-button>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
.small {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
</style>
