<template>
  <b-dropdown
    :size="size"
    block
    toggle-class="filter-dropdown d-flex align-items-center justify-content-between"
    menu-class="w-100"
    variant="n"
  >
    <template #button-content>
      <span class="text-truncate">{{ text }}</span>
      <b-icon class="ml-auto" icon="chevron-down"></b-icon>
    </template>
    <div class="items-wrapper">
      <slot />
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: "EgDropdown",
  props: {
    text: {
      type: String,
    },
    size: {
      type: String,
      default: "md",
    },
  },
};
</script>

<style lang="scss" scoped>
.items-wrapper {
  max-height: 300px;
  overflow: auto;
}
.dropdown {
  &::v-deep {
    .dropdown-toggle {
      border: 1px solid #ced4da; // TODO: change to CSS variable

      &::after {
        display: none;
      }
    }
  }
}
</style>
