<script>
import { mapActions } from "vuex";
import { getEssay, deleteEssay, getClasses, updateEssay } from "../api";
import regradeMixin from "@/mixins/regradeMixin";
import { getIntensityDisplayText, formatDefaultType } from "../utils/format";
import formatDate from "../utils/formatDate";
import strIsHTML from "../utils/strIsHTML";
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import EssayActionsDropdown from "@/components/essay/essay_actions_dropdown.vue";
import EssayReportEditor from "@/components/essay/essay_report_editor.vue";
import MoveEssayModal from "@/components/essay/modals/move_essay_modal.vue";
import EssayRegradeModal from "@/components/essay/essay_regrade_modal.vue";
import ExportToClassroomModal from "@/components/essay/modals/export_to_classroom.vue";

const trimHTML = (html) => {
  return html.replaceAll("\n", "");
};

export default {
  name: "EssayView",
  components: {
    Breadcrumbs,
    EssayActionsDropdown,
    MoveEssayModal,
    EssayReportEditor,
    EssayRegradeModal,
    ExportToClassroomModal,
  },

  mixins: [
    // this.regrade
    regradeMixin,
  ],

  data() {
    return {
      regrading: false,
      loading: true,
      error: "",
      essay: null,
      copyError: "",
      classesLoading: false,
      classesError: "",
      // DEPRECATED: delete on next release
      selectedClass: null,
      // DEPRECATED: delete on next release
      classOptions: [],
      feedbackEditorShow: false,
      errorsEditorShow: false,
    };
  },
  async created() {
    try {
      const essay = await getEssay(this.$route.params.id);

      this.essay = essay.data;

      this.loading = false;
      this.error = "";
    } catch (error) {
      this.loading = false;
      this.error = error.response?.data?.error;
    }
  },
  computed: {
    canGoAiDetector() {
      return this.$store.getters.userIsNotPausedAndPlanMoreThenPro;
    },

    isHTML() {
      const document = new DOMParser().parseFromString(
        this.essay.errors,
        "text/html"
      );

      return Array.from(document.body.childNodes).some(
        (node) => node.nodeType === 1
      );
    },
    essayFeedback() {
      return strIsHTML(this.essay.feedback)
        ? trimHTML(this.essay.feedback)
        : this.essay.feedback;
    },
    essayErrors() {
      return strIsHTML(this.essay.errors)
        ? trimHTML(this.essay.errors)
        : this.essay.errors;
    },
    gradedDate() {
      return formatDate(this.essay.createdAt);
    },
    breadcrumbs() {
      return [
        {
          text: "Essays",
          href: "/essays",
        },
        {
          text: this.essay.name || "Essay",
          active: true,
        },
      ];
    },
    gradeLevel() {
      return this.essay.gradeLevel;
    },
    essayType() {
      return this.essay.type;
    },
    essayClass() {
      return this.essay.class;
    },

    isReviewed() {
      return this.essay.status === "Reviewed";
    },
  },

  methods: {
    ...mapActions(["setAiDetectorEssayContent"]),
    async updateContent(type = "feedback", newHtml) {
      this.essay[type] = newHtml;
      try {
        await updateEssay({
          essayId: this.essay.id,
          [type]: newHtml,
        });
        this.$bvToast.toast(
          `The essay ${type} section was successfully saved.`,
          {
            title: "Success",
            variant: "success",
            solid: true,
          }
        );
      } catch (error) {
        this[`${type}EditorShow`] = true;
        this.$bvToast.toast(
          `There was a problem saving the ${type} section. Please try again.`,
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    printEssayFeedback() {
      window.print();
    },
    async copyData({ data, type }) {
      try {
        let richText = data;
        let plainText = data;

        if (this.isHTML) {
          let div = document.createElement("div");
          div.innerHTML = data;
          richText = div.innerHTML;
          plainText = div.innerText || "";
          div = null;
        }

        await this.copyRichText(richText, plainText);

        this.$bvToast.toast(
          `The essay ${type} section was copied successfully.`,
          {
            title: "Copy",
            variant: "success",
            autoHideDelay: 3000,
            solid: true,
          }
        );
      } catch {
        this.$bvToast.toast(
          `There was an error copying the essay ${type} section. Please try again.`,
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    async copyRichText(rich, plain) {
      if (typeof ClipboardItem !== "undefined") {
        // Shiny new Clipboard API, not fully supported in some browsers Firefox.
        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
        const html = new Blob([rich], { type: "text/html" });
        const text = new Blob([plain], { type: "text/plain" });
        const data = new ClipboardItem({
          "text/html": html,
          "text/plain": text,
        });
        await navigator.clipboard.write([data]);
      } else {
        // Fallback using the deprecated `document.execCommand`.
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
        const cb = (e) => {
          e.clipboardData.setData("text/html", rich);
          e.clipboardData.setData("text/plain", plain);
          e.preventDefault();
        };
        document.addEventListener("copy", cb);
        document.execCommand("copy");
        document.removeEventListener("copy", cb);
      }
    },
    async deleteSingleEssay() {
      try {
        this.loading = true;

        await deleteEssay(this.essay.id);

        this.$router.push("/essays?deleted=true");
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    // DEPRECATED: delete on next release
    async fetchClasses() {
      // safeguard to ensure we don't fetch for classes
      // more than once.
      if (this.classOptions.length === 0) {
        try {
          this.classesLoading = true;

          const { data } = await getClasses();

          this.classesLoading = false;
          this.classesError = "";

          data.classes.forEach((foundClass) => {
            this.classOptions.push({
              text: foundClass.name,
              value: foundClass.id,
            });
          });
        } catch (error) {
          this.classesLoading = false;
          this.classesError = error.response?.data?.error;
        }
      }
    },
    // DEPRECATED: delete on next release
    async moveEssay() {
      try {
        this.loading = true;

        const { data } = await updateEssay({
          essayId: this.essay.id,
          classId: this.selectedClass,
        });

        this.essay = data;

        this.loading = false;
        this.error = "";
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },

    handleClickReview() {
      if (this.isReviewed) return;

      this.changeStatus({ status: "Reviewed" });
    },

    async changeStatus({ status }) {
      try {
        this.loading = true;

        await updateEssay({
          essayId: this.essay.id,
          status,
        });

        this.essay.status = status;

        this.$bvToast.toast(`The essay was marked as ${status}.`, {
          title: "Success",
          variant: "success",
          solid: true,
          autoHideDelay: 5000,
        });
        this.loading = false;
        this.error = "";
      } catch (error) {
        this.$bvToast.toast("Something went wrong. Please try again.", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        this.error = error.response?.data?.error;
        this.loading = false;
      }
    },
    showEssayMovedModal() {
      this.$bvModal.show("move-essay-modal");
    },

    async regradeHandler() {
      this.regrading = true;
      await this.regrade(this.essay, false);
      this.regrading = false;
    },

    redirectToEssays() {
      this.$router.push("/essays?moved=true");
    },
    setMoveError(error) {
      this.error = error;
    },
    // DEPRECATED: delete on next release
    clearSelectedClass() {
      this.selectedClass = null;
    },

    getIntensityDisplayText(intensity) {
      return getIntensityDisplayText(intensity);
    },

    formatDefaultType(essay) {
      return formatDefaultType(essay);
    },

    async handleClickAiDetector() {
      if (!this.canGoAiDetector) {
        this.$bvModal.show("upgrade-modal");
        return;
      }
      await this.setAiDetectorEssayContent(this.essay.content);
      this.$router.push("/ai-detector");
    },
  },
};
</script>

<template>
  <b-container>
    <section v-if="loading || error">
      <b-row>
        <b-col>
          <div v-if="loading" class="text-center">
            <b-spinner class="mb-3"></b-spinner>
            <p>Processing request...</p>
          </div>
          <b-alert v-else variant="danger" show>{{ error }}</b-alert>
        </b-col>
      </b-row>
    </section>
    <section v-else>
      <breadcrumbs :items="breadcrumbs" />
      <b-alert v-if="copyError" class="mb-5" variant="danger" show>
        {{ copyError }}
      </b-alert>
      <b-row>
        <b-col lg="6" md="9">
          <div>
            <h1 v-b-tooltip.hover :title="essay.name" class="mb-1">
              {{ essay.name }}
            </h1>
          </div>
          <div>
            <b-badge v-if="essayClass" class="mr-2" pill>
              <b-icon icon="folder"></b-icon>
              {{ essayClass }}
            </b-badge>
            <b-badge v-if="essay.Rubric" class="mr-2" pill>
              <b-icon icon="book"></b-icon>
              {{ essay.Rubric.name }}
            </b-badge>
            <b-badge v-else-if="essay.type" class="mr-2" pill>
              <b-icon icon="book"></b-icon>
              {{ formatDefaultType(essay) }}
            </b-badge>

            <b-badge v-if="essay.intensity" class="mr-2" pill>
              <b-icon icon="bookshelf"></b-icon>
              {{ getIntensityDisplayText(essay.intensity) }}
            </b-badge>
            <span class="light-grey-text">Graded on {{ gradedDate }}</span>
          </div>
        </b-col>
        <b-col lg="6" md="3" class="print-feedback-column">
          <div class="text-right">
            <b-button
              class="mr-2"
              :variant="isReviewed ? 'secondary' : 'info'"
              @click="handleClickReview"
            >
              <b-icon
                class="mr-0 mr-lg-2"
                icon="check2-circle"
                aria-hidden="true"
              />
              <span>{{ isReviewed ? "Reviewed" : "Ready for review" }}</span>
            </b-button>
            <b-button
              class="btn-ai mr-2"
              variant="outline-info"
              @click="handleClickAiDetector"
            >
              <img
                class="mr-0 mr-lg-2"
                src="../assets/icons/stars.svg"
                alt=""
              />
              <img
                class="hover mr-0 mr-lg-2"
                src="../assets/icons/stars-white.svg"
                alt=""
              />
              <span>Run AI detection</span>
            </b-button>
            <essay-actions-dropdown
              :essay="essay"
              variant="secondary"
              @move="showEssayMovedModal"
              @delete="deleteSingleEssay"
              @regrade="regradeHandler"
              @print="printEssayFeedback"
              @export="$bvModal.show('export-to-classroom')"
            />
          </div>
        </b-col>
      </b-row>

      <hr />

      <b-row v-if="regrading" class="mt-4">
        <b-col>
          <div class="text-center mt-4">
            <b-spinner
              variant="info"
              label="Regrading..."
              style="width: 64px; height: 64px"
            />
            <h2 class="mt-4">Re-grading {{ essay.name }}...</h2>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="mt-4">
        <b-col>
          <div>
            <div class="mb-4">
              <div class="d-flex justify-content-between">
                <h2>Essay Feedback Report</h2>

                <div class="text-right">
                  <b-button
                    class="mb-3 mr-3"
                    @click="feedbackEditorShow = !feedbackEditorShow"
                  >
                    {{ feedbackEditorShow ? "Save" : "Edit" }}
                  </b-button>
                  <b-button
                    class="mb-3"
                    @click="copyData({ data: essayFeedback, type: 'feedback' })"
                  >
                    Copy feedback
                  </b-button>
                </div>
              </div>
              <essay-report-editor
                v-if="feedbackEditorShow"
                class="feedback-box"
                :content="essayFeedback"
                @updated="(newHtml) => updateContent('feedback', newHtml)"
              />
              <div v-else class="feedback-box" v-html="essayFeedback"></div>
            </div>

            <div class="mb-4">
              <div class="d-flex justify-content-between">
                <h2>Error Report</h2>
                <div class="text-right">
                  <b-button
                    class="mb-3 mr-3"
                    @click="errorsEditorShow = !errorsEditorShow"
                  >
                    {{ errorsEditorShow ? "Save" : "Edit" }}
                  </b-button>
                  <b-button
                    class="mb-3"
                    @click="copyData({ data: essayErrors, type: 'errors' })"
                  >
                    Copy errors
                  </b-button>
                </div>
              </div>
              <essay-report-editor
                v-if="errorsEditorShow"
                class="feedback-box"
                :content="essayErrors"
                @updated="(newHtml) => updateContent('errors', newHtml)"
              />
              <div v-else class="feedback-box" v-html="essayErrors"></div>
            </div>

            <div class="mb-5">
              <h2 class="mb-3">Original Essay</h2>

              <b-button v-b-toggle.collapse-1 block class="text-left">
                Toggle original essay
              </b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <div class="feedback-box" v-html="essay.content"></div>
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-row>

      <move-essay-modal
        :essayId="essay.id"
        @essayMoved="redirectToEssays"
        @moveError="setMoveError"
      />
      <essay-regrade-modal
        :essay="essay"
        :essayId="essay.id"
        @startRegrade="regrading = true"
        @finishRegrade="regrading = false"
      />

      <export-to-classroom-modal :essay="essay" />
    </section>
  </b-container>
</template>

<style scoped lang="scss">
.feedback-box.ql-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
.feedback-box::v-deep {
  p,
  h2,
  h3 {
    margin: 0;
  }

  h3:not(:first-child) {
    margin-top: 15px;
  }

  h2:not(:first-child) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  h3 {
    margin-bottom: 15px;
  }

  .criteria-grade {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 20px;
  }

  .ip-suggestion,
  .sp-error,
  .g-error,
  .s-error,
  .c-error,
  .cl-error,
  .l-error,
  .g-suggestion,
  .s-suggestion,
  .c-suggestion,
  .cl-suggestion,
  .l-suggestion,
  .g-impact,
  .s-impact,
  .c-impact,
  .cl-impact,
  .l-impact {
    display: block;
    white-space: normal;
    margin-bottom: 0px;
  }

  .ip-suggestion,
  .g-suggestion,
  .s-suggestion,
  .c-suggestion,
  .cl-suggestion,
  .l-suggestion {
    margin-bottom: 20px;
  }

  h2 {
    border-bottom: 1px solid gray;
  }
}

.btn {
  display: inline;

  img {
    display: inline;
    width: 20px;
    height: 20px;

    &.hover {
      display: none;
    }
  }

  &:hover {
    img {
      display: none;

      &.hover {
        display: inline;
      }
    }
  }

  @media (max-width: 991px) {
    span {
      display: none;
    }
  }
}
</style>
