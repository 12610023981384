<script>
export default {
  props: {
    clientDisabledMessage: String,
  },
};
</script>

<template>
  <b-container class="mt-3">
    <b-alert variant="warning" show>
      <p class="mb-0">
        {{ clientDisabledMessage }}
      </p>
    </b-alert>
  </b-container>
</template>
