<script>
import formatDate from "@/utils/formatDate";
import { getIntensityDisplayText, formatDefaultType } from "@/utils/format";
import EssayActionsDropdown from "./essay_actions_dropdown.vue";
import EssayRegradeModal from "./essay_regrade_modal.vue";
import EssaysTableFilters from "./essays_table_filters.vue";
import EmptyState from "../global/empty_state.vue";
import regradeMixin from "@/mixins/regradeMixin";

const DEFAULT_FIELDS = [
  {
    key: "status",
    label: "Status",
    columnClass: "col-status",
    sortable: true,
  },
  {
    key: "name",
    label: "Essay",
    tdClass: "text-truncate",
    columnClass: "col-name",
    sortable: true,
  },
  {
    key: "Rubric",
    label: "Rubric",
    columnClass: "col-rubric",
    sortable: true,
  },
  {
    key: "grade",
    label: "Grade",
    columnClass: "col-grade",
    sortable: true,
  },
  {
    key: "createdAt",
    label: "Graded on",
    columnClass: "col-created-at",
    sortable: true,
  },
  {
    key: "actions",
    label: "",
    tdClass: "text-right",
    columnClass: "col-actions",
  },
];

export default {
  name: "EssaysTable",
  components: {
    EssayActionsDropdown,
    EssayRegradeModal,
    EssaysTableFilters,
    EmptyState,
  },

  mixins: [regradeMixin],

  props: {
    essays: {
      type: Array,
      required: true,
    },
    availableFilters: {
      type: Array,
      default: () => ["name", "class", "rubric", "assignment"],
    },
    fields: {
      type: Array,
      required: false,
      default: () => DEFAULT_FIELDS,
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    async regradeHandler(essay) {
      const regraded = await this.regrade(essay);
      regraded && this.$emit("getData");
    },
    isGrading(item) {
      return item.status === "Queued";
    },
    formattedDate(date) {
      return formatDate(date);
    },
    getVariantByStatus(status) {
      switch (status) {
        case "Rejected":
          return "danger";
        case "Reviewed":
          return "success";
        default:
          return "secondary";
      }
    },

    getIntensityDisplayText(intensity) {
      return getIntensityDisplayText(intensity);
    },

    formatDefaultType(essay) {
      return formatDefaultType(essay);
    },
    getDisplayTextForStatus(status) {
      switch (status) {
        case "Rejected":
          return "Rejected";
        case "Reviewed":
          return "Reviewed";
        default:
          return "Ready";
      }
    },
  },
};
</script>

<template>
  <div class="essays-table">
    <essays-table-filters
      :available-filters="availableFilters"
      @filter-changed="$emit('getData', $event)"
    ></essays-table-filters>

    <b-col v-if="tableLoading" cols="12" class="mt-5">
      <b-skeleton-table :rows="12" :columns="4"></b-skeleton-table>
    </b-col>
    <empty-state
      v-else-if="!essays.length"
      title="No essays have been matches the filter."
      description="Try filtering by a different value."
    >
    </empty-state>
    <b-table
      v-else
      :items="essays"
      :fields="fields"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      stacked="md"
      no-local-sorting
      @sort-changed="$emit('getData', $event)"
      class="essay-table"
    >
      <template #head()="{ label, field }">
        {{ label }}
        <b-icon
          v-if="field.sortable"
          :style="{ color: sortBy === field.key ? '#343A40' : 'unset' }"
          :icon="
            sortBy === field.key
              ? `sort-${sortDesc ? 'down' : 'up'}-alt`
              : 'sort-down-alt'
          "
        />
      </template>
      <template #head(actions)="{ label }">
        {{ label }}
      </template>
      <template #table-colgroup="{ fields }">
        <col
          v-for="field in fields"
          :key="field.key"
          :class="field.columnClass"
        />
      </template>

      <template #cell(status)="{ item }">
        <b-badge
          class="badge-pill-queued"
          v-if="isGrading(item)"
          pill
          variant="cyan"
        >
          <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
          {{ item.status }}
        </b-badge>
        <b-badge v-else pill :variant="getVariantByStatus(item.status)">
          <b-icon
            v-if="item.status === 'Reviewed'"
            icon="check2"
            scale="1.3"
          ></b-icon>
          <b-icon
            v-if="item.status === 'Graded'"
            icon="check2-circle"
            scale="1.3"
          ></b-icon>
          {{ getDisplayTextForStatus(item.status) }}
        </b-badge>
      </template>
      <template #cell(name)="{ item }">
        <div>
          <span v-if="isGrading(item)" class="font-rubik">
            {{ item.name }}</span
          >
          <a v-else :href="`/essay/${item.id}`" class="font-rubik">
            {{ item.name }}
          </a>
          <div class="d-flex">
            <div
              v-if="item.intensity"
              class="additional-text mr-2"
              v-b-tooltip.hover
              :title="getIntensityDisplayText(item.intensity)"
            >
              <b-icon icon="bookshelf" />
              {{ getIntensityDisplayText(item.intensity) }}
            </div>
            <div
              v-if="item.Student"
              class="additional-text mr-2"
              v-b-tooltip.hover
              :title="`${item.Student.firstName} ${item.Student.lastName}`"
            >
              <b-icon icon="people" />
              {{ item.Student.firstName }} {{ item.Student.lastName }}
            </div>
            <div
              v-if="item.Assignment"
              class="additional-text mr-2"
              v-b-tooltip.hover
              :title="item.Assignment.title"
            >
              <b-icon icon="people" />
              {{ item.Assignment.title }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(Rubric)="{ item }">
        <span v-if="item.Rubric" class="small">
          <a :href="`/rubric/edit/${item.Rubric.id}?type=preview`" class="link">
            {{ item.Rubric.name || `#${item.Rubric.id}` }}
          </a>
        </span>
        <span v-else-if="item.type" class="small">
          {{ formatDefaultType(item) }}
        </span>
        <span v-else class="small">None</span>
      </template>
      <template #cell(grade)="{ item }">
        <b-skeleton
          class="skeleton_loading_bar"
          width="80%"
          v-if="isGrading(item)"
        />
        <span v-else-if="item.grade">
          {{ item.grade }}
        </span>
        <span v-else> None </span>
      </template>
      <template #cell(createdAt)="{ item }">
        <b-skeleton
          class="skeleton_loading_bar"
          width="90%"
          v-if="isGrading(item)"
        />
        <span v-else>
          {{ formattedDate(item.createdAt) }}
        </span>
      </template>
      <template #cell(actions)="{ item }">
        <essay-actions-dropdown
          v-if="!isGrading(item)"
          :essay="item"
          @move="$emit('move', $event)"
          @delete="$emit('delete', $event)"
          @regrade="regradeHandler"
          @export="$emit('export', $event)"
        />
      </template>
    </b-table>

    <essay-regrade-modal
      :essay="regradedEssay"
      @finishRegrade="$emit('getData')"
    />
  </div>
</template>

<style lang="scss" scoped>
.table {
  &::v-deep {
    td,
    th {
      padding: 0.55rem;
    }
  }
}

.link {
  color: #343a40;
  text-decoration: underline;

  &:hover {
    color: #0d6efd;
  }
}

.essay-table {
  table-layout: fixed;
  overflow-wrap: break-word;
}

.badge-pill {
  width: 83px;
  color: white;
  text-align: left;

  svg {
    margin-right: 3px;
  }
}

.badge-cyan {
  background-color: #17a2b8;
}

.badge-secondary {
  background-color: #6c757d;
}

.additional-text {
  font-size: 12px;
  color: var(--gray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-status {
  width: 97px;
}

.col-name {
  width: 60%;
}

.col-rubric {
  width: 96px;

  @media (min-width: 992px) {
    width: 40%;
  }
}

.col-class-id {
  width: 105px;
}

.col-grade {
  width: 91px;
}

.col-created-at {
  width: 124px;

  @media (min-width: 992px) {
    width: 150px;
  }
}

.col-actions {
  width: 38px;

  @media (min-width: 992px) {
    width: 68px;
  }
}

.skeleton_loading_bar {
  background-color: rgba(0, 0, 0, 0.25);
  height: 20px;
}
</style>
