<template>
  <div>
    <b-form-group label="What level are we grading at?">
      <div :class="horizontal ? 'd-flex' : 'd-block'">
        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="gradeLevel"
          name="grading-level"
          @change="changeGradeLevel"
          value="elementary"
        >
          Elementary
        </b-form-radio>
        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="gradeLevel"
          name="grading-level"
          @change="changeGradeLevel"
          value="middleschool"
        >
          Middle school
        </b-form-radio>

        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="gradeLevel"
          name="grading-level"
          @change="changeGradeLevel"
          value="highschool"
        >
          High school
        </b-form-radio>

        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="gradeLevel"
          name="grading-level"
          @change="changeGradeLevel"
          value="college"
        >
          College
        </b-form-radio>
      </div>
    </b-form-group>

    <b-form-group label="What type of essay are we grading?">
      <div :class="horizontal ? 'd-flex' : 'd-block'">
        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="essayType"
          name="essay-type"
          value="persuasive"
          @change="changeEssayType"
        >
          Persuasive
        </b-form-radio>
        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="essayType"
          name="essay-type"
          value="descriptive"
          @change="changeEssayType"
        >
          Descriptive
        </b-form-radio>

        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="essayType"
          name="essay-type"
          value="narrative"
          @change="changeEssayType"
        >
          Narrative
        </b-form-radio>

        <b-form-radio
          :class="horizontal && 'mr-3'"
          :checked="essayType"
          name="essay-type"
          value="expository"
          @change="changeEssayType"
        >
          Expository
        </b-form-radio>
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    essayType: {
      type: String,
      required: true,
    },
    gradeLevel: {
      type: String,
      required: true,
    },
  },

  methods: {
    changeEssayType(value) {
      this.$emit("input", { type: "essayType", value });
    },

    changeGradeLevel(value) {
      this.$emit("input", { type: "gradeLevel", value });
    },
  },
};
</script>
