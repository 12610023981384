<template>
  <b-modal
    id="export-to-classroom"
    title="Export feedback to Google Classroom"
    size="lg"
    centered
    @show="onShow"
    @hide="onHide"
  >
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center justify-content-end">
        <b-button variant="secondary" @click="close">{{ closeText }}</b-button>
        <template v-if="essay">
          <b-button
            v-if="essay.exportedAt && !processed"
            class="ml-2"
            variant="info"
            @click="() => exportSelectedToClassroom([essay.id])"
          >
            Re-export feedback
          </b-button>
          <google-button
            v-if="resultStatus === 'auth' && processed"
            class="ml-2"
            type="permissions"
            :scope="permissionScope"
            @done="() => exportSelectedToClassroom([essay.id])"
          >
            Continue with google
          </google-button>
          <b-button
            v-if="
              processed &&
              resultStatus === 'success' &&
              exportedEssays?.[0]?.exportLink
            "
            class="ml-2"
            variant="info"
            :href="exportedEssays[0].exportLink"
            target="_blank"
          >
            <div>
              View Google Doc
              <b-icon
                class="icon-box-arrow-up-right"
                icon="box-arrow-up-right"
              />
            </div>
          </b-button>

          <b-button
            v-if="processed && resultStatus === 'error'"
            class="ml-2"
            variant="info"
            @click="() => exportSelectedToClassroom([essay.id])"
          >
            Retry export
          </b-button>
        </template>

        <template v-else>
          <b-button
            v-if="!processed || (processed && resultStatus === 'warning')"
            class="ml-2"
            variant="info"
            :disabled="exporting || selectedIds.length === 0"
            @click="
              !processed ? exportSelectedToClassroom() : retryAllFailedExports()
            "
          >
            {{ !processed ? "Export" : "Retry all failed exports" }}
          </b-button>
          <b-button
            v-if="processed && resultStatus === 'error'"
            class="ml-2"
            variant="info"
            @click="retryExport"
          >
            Retry export
          </b-button>
          <google-button
            v-if="processed && resultStatus === 'auth'"
            class="ml-2"
            type="permissions"
            :scope="permissionScope"
            @done="exportSelectedToClassroom(selectedIds)"
          >
            Re-authenticate & Export
          </google-button>
        </template>
      </div>
    </template>

    <template v-if="essay">
      <div v-if="!processed">
        <div v-if="exporting">
          <div
            class="d-flex flex-column align-items-center justify-content-center w-100"
            style="height: 300px"
          >
            <b-spinner
              class="my-5"
              style="width: 51px; height: 51px"
              variant="info"
            />

            <h4 class="text-center">Exporting to Google Classroom...</h4>
          </div>
        </div>

        <div v-else-if="essay.exportedAt" class="text">
          The feedback was exported to Google Classroom on
          {{ formatDate(essay.exportedAt) }}.

          <div>
            <a :href="essay.exportLink || '#'" target="_blank">
              View Google Doc
              <b-icon
                class="icon-box-arrow-up-right"
                icon="box-arrow-up-right"
              />
            </a>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="resultStatus === 'auth'">
          <div class="text-center">
            <img
              class="mt-1 mb-2"
              src="../../../assets/illustrations/empty-state-submissions.svg"
              alt="Export to Classroom"
              style="width: 171px; height: 171px"
            />
          </div>

          <div class="mb-2">
            EssayGrader can add feedback to the Google Document that students
            submit in Google Classroom. We need permission to edit files to do
            that.
          </div>

          <b>Don’t forget!</b>

          <ul>
            <li>Allow EssayGrader to edit files by selecting</li>
            <li>
              “Select all”. We will not change any document sharing permissions.
            </li>
          </ul>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center justify-content-center w-100"
        >
          <b-icon
            :icon="result.icon"
            :variant="result.variant"
            :style="{ width: '48px', height: '48px' }"
          />
          <h4 class="text-center mt-2">
            {{ result.label }}
          </h4>

          <div v-if="result.text" class="text text-center mb-3">
            {{ result.text }}
          </div>
        </div>
      </div>
    </template>

    <div v-else>
      <div v-if="exporting">
        <div
          class="d-flex flex-column align-items-center justify-content-center w-100"
          style="height: 300px"
        >
          <b-spinner
            class="my-5"
            style="width: 51px; height: 51px"
            variant="info"
          />

          <h4 class="text-center">Exporting to Google Classroom...</h4>
        </div>
      </div>
      <div v-if="!exporting">
        <div v-if="!processed" class="text mb-3">
          Easily share essay feedback with students on Google Classroom. This
          feature only works for Google doc submissions. Exporting feedback for
          the same submission will add the feedback text again, rather than
          replace the old feedback.
        </div>

        <div
          v-else
          class="d-flex flex-column align-items-center justify-content-center w-100"
        >
          <b-icon
            :icon="result.icon"
            :variant="result.variant"
            :style="{ width: '48px', height: '48px' }"
          />
          <h4 class="text-center mt-2">
            {{ result.label }}
          </h4>

          <div v-if="result.text" class="text text-center mb-3">
            {{ result.text }}
          </div>
        </div>

        <div v-if="show && !result.text">
          <assignment-select
            v-if="!processed"
            class="assignment-select"
            @input="selectAssignment"
          >
            <template #item="{ assignment }">
              <div class="d-flex align-items-center justify-content-between">
                <div>{{ assignment.title }}</div>
                <b-badge v-if="assignment.class" class="mr-2" pill>
                  <b-icon icon="folder"></b-icon>
                  {{ assignment.class.name }}
                </b-badge>
              </div>
            </template>
          </assignment-select>

          <div
            v-if="selectedAssignment"
            class="d-flex align-items-center justify-content-center w-100"
          >
            <template v-if="loading">
              <b-spinner
                class="my-5"
                style="width: 51px; height: 51px"
                variant="info"
              ></b-spinner>
            </template>
            <template v-else>
              <empty-state
                v-if="filteredEssays.length === 0"
                class="mt-3 mb-3"
                title="No submissions yet"
                description="Select a different assignment."
                small
              >
                <img
                  class="mb-2"
                  src="../../../assets/illustrations/empty-state-submissions.svg"
                  alt="No submissions"
                />
              </empty-state>
              <eg-selectable-table
                v-else
                v-model="selectedIds"
                :selectable="!processed"
                :items="processed ? exportedEssays : filteredEssays"
                :fields="fields"
                class="w-100"
              >
                <template
                  v-if="!processed"
                  v-slot:selectAll="{ selectedAll, selectAll }"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <b-dropdown size="sm" split variant="outline-secondary">
                      <template #button-content>
                        <b-checkbox
                          class="small-checkbox"
                          size="sm"
                          variable="info"
                          :checked="selectedAll"
                          :indeterminate="
                            selectedIds.length > 0 &&
                            selectedIds.length < filteredEssays.length
                          "
                          @change="selectAll"
                        >
                          Select
                        </b-checkbox>
                      </template>
                      <b-dropdown-item-button @click="selectAll">
                        All
                      </b-dropdown-item-button>
                      <b-dropdown-item-button @click="selectNeverItems">
                        All that have never been exported
                      </b-dropdown-item-button>
                      <b-dropdown-item-button @click="selectedIds = []">
                        None
                      </b-dropdown-item-button>
                    </b-dropdown>

                    <b-input
                      class="ml-2"
                      size="sm"
                      placeholder="Filter by student name"
                      @input="onInputFilter"
                    />
                  </div>
                </template>
              </eg-selectable-table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getAllEssays, exportToGoogle } from "@/api";
import debounce from "@/utils/debounce";
import formatDate from "@/utils/formatDate";
import AssignmentSelect from "@/components/essay/assignment_select";
import EgSelectableTable from "@/components/global/eg_selectable_table";
import GoogleButton from "@/components/auth/google_button.vue";
import EmptyState from "@/components/global/empty_state.vue";
import { CLASSROOM_SCOPE } from "@/constants";

const results = (data) => ({
  success: {
    icon: "check-circle-fill",
    label: "Export complete!",
    variant: "success",
  },
  warning: {
    icon: "exclamation-triangle-fill",
    label: "Some exports failed",
    variant: "warning",
  },
  error: {
    icon: "exclamation-circle-fill",
    label: "Export failed",
    variant: "danger",
    text: "Something went wrong.",
  },
  auth: {
    icon: "exclamation-circle-fill",
    label: "Authentication credentials expired",
    variant: "danger",
    text: `Re-authenticate your google account ${
      data.email ? data.email : ""
    } to export essays.`,
  },
});
export default {
  components: {
    AssignmentSelect,
    EgSelectableTable,
    GoogleButton,
    EmptyState,
  },

  props: {
    essay: {
      type: Object,
    },
  },

  data() {
    return {
      permissionScope: CLASSROOM_SCOPE,
      show: false,
      loading: false,
      filterValue: "",
      exporting: false,
      processed: false,
      selectedAssignment: null,
      selectedIds: [],
      assignments: [
        { id: 1, name: "Assignment 1" },
        { id: 2, name: "Assignment 2" },
      ],
      essays: [],
      exportedEssays: [],
      results,
      resultStatus: "success",
      resultData: {},
    };
  },

  computed: {
    result() {
      return this.results(this.resultData)[this.resultStatus];
    },

    fields() {
      const studentName = {
        key: "student",
        label: "Student name",
        formatter: (value, key, item) => {
          const student = item.Student;

          return `${student.firstName} ${student.lastName}`;
        },
      };
      const creationTime = {
        key: "createdAt",
        label: "Imported on",
        formatter: (value) => {
          return formatDate(value);
        },
      };
      const updatedTime = {
        key: "exportedAt",
        label: "Exported last",
        formatter: (value) => {
          return value ? formatDate(value) : "Never";
        },
      };

      const actionLink = {
        key: "action",
        label: this.processed ? "Google Doc" : "",
        formatter: (value, key, item) => {
          if (item.success === undefined || item.success) {
            return {
              link: item.exportLink,
              label: "View",
            };
          }

          return {
            action: (item) => {
              this.retryEssayExportByIds([item.id]);
            },
            label: "Retry export",
            props: {
              variant: "outline-info",
            },
          };
        },
      };

      if (!this.processed) {
        return [studentName, creationTime, updatedTime, actionLink];
      }

      return [
        {
          key: "icon",
          label: "",
          formatter: (value, key, item) => {
            return {
              icon: item.success
                ? "check-circle-fill"
                : "exclamation-circle-fill",
              style: {
                color: item.success ? "#28a745" : "#dc3545",
              },
            };
          },
        },
        studentName,
        updatedTime,
        actionLink,
      ];
    },

    filteredEssays() {
      if (!this.filterValue) return this.essays;

      return this.essays.filter((essay) => {
        const student = essay.Student;

        return (
          student.firstName
            .toLowerCase()
            .includes(this.filterValue.toLowerCase()) ||
          student.lastName
            .toLowerCase()
            .includes(this.filterValue.toLowerCase())
        );
      });
    },

    closeText() {
      return this.processed ? "Close" : "Cancel";
    },
  },

  methods: {
    formatDate,

    retryExport() {
      this.processed = false;
      this.resultStatus = "success";
    },

    updateExportingStatuses() {
      const essaysSuccessStatuses = this.exportedEssays
        .map((essay) => {
          return essay.success;
        })
        .filter((status) => status === true);

      if (essaysSuccessStatuses.length === 0) {
        this.resultStatus = "error";
      } else if (essaysSuccessStatuses.length < this.exportedEssays.length) {
        this.resultStatus = "warning";
      } else {
        this.resultStatus = "success";
      }
    },

    retryAllFailedExports() {
      const failedExportEssayIds = this.exportedEssays
        .filter((exportedEssay) => !exportedEssay.success)
        .map((exportedEssay) => exportedEssay.id);

      return this.retryEssayExportByIds(failedExportEssayIds);
    },

    async retryEssayExportByIds(essayIds) {
      try {
        const data = await this.exportToClassroom(essayIds);
        data.forEach((essay) => {
          const exportedEssay = this.exportedEssays.find((exportedEssay) => {
            return exportedEssay.id === essay.id;
          });

          // change data in exportedEssays by link
          this.$set(exportedEssay, "success", essay.success);
          this.$set(exportedEssay, "exportLink", essay.exportLink);
        });

        this.updateExportingStatuses();
      } catch (error) {
        // ignore errors and continue
      }
    },

    async exportSelectedToClassroom(essayIds = this.selectedIds) {
      try {
        const data = await this.exportToClassroom(essayIds);
        this.exportedEssays = data;

        this.updateExportingStatuses();
      } catch (error) {
        // ignore errors and continue
      }
    },

    async exportToClassroom(essayIds = this.selectedIds) {
      this.exporting = true;
      try {
        const { data } = await exportToGoogle(essayIds);
        this.exporting = false;
        this.processed = true;

        return data;
      } catch (error) {
        this.exporting = false;
        if (
          error.response.data.code === "missedPermissions" ||
          error.response.data.error.includes("permissions are missed")
        ) {
          this.processed = true;
          this.resultStatus = "auth";

          if (error.response.data.meta.email) {
            this.resultData = { email: error.response.data.meta.email };
          }

          throw error;
        }

        this.$bvToast.toast(error.message, {
          title: "Error",
          variant: "danger",
        });
      }
    },

    selectAssignment(assignment) {
      this.selectedAssignment = assignment;
      this.loadEssays();
    },

    selectNeverItems() {
      this.selectedIds = this.essays
        .filter((essay) => {
          return !essay.exportedAt;
        })
        .map((essay) => essay.id);
    },

    onInputFilter: debounce(function (value = "") {
      this.filterValue = value;
    }, 500),

    async loadEssays() {
      this.loading = true;
      try {
        const { data } = await getAllEssays({
          page: 1,
          filters: { AssignmentId: this.selectedAssignment.id },
        });

        this.essays = data.rows.filter((essay) => essay.exportable);
        this.selectedIds = this.essays.map((essay) => essay.id);
        this.loading = false;
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: "Error",
          variant: "danger",
        });
        this.loading = false;
      }
    },

    async onShow() {
      this.show = true;

      this.$nextTick(() => {
        if (this.essay && !this.essay.exportedAt) {
          this.exportSelectedToClassroom([this.essay.id]);
        }
      });
    },

    onHide() {
      this.show = false;
      this.selectedAssignment = null;
      this.selectedIds = [];
      this.essays = [];
      this.processed = false;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
#export-to-classroom {
  &::v-deep {
    .modal-body {
      min-height: 330px;
    }
  }
}
.small-checkbox.custom-checkbox.b-custom-control-sm {
  min-height: 1rem;
}

.icon-box-arrow-up-right {
  margin-bottom: 1px;
  margin-left: 1px;
}

.assignment-select {
  &::v-deep {
    .items-wrapper {
      max-height: 130px;
    }
  }
}
</style>
