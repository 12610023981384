<template>
  <div v-if="loading" class="d-flex">
    <b-col cols="3">
      <b-skeleton-img no-aspect height="136px"></b-skeleton-img>
    </b-col>
    <b-col cols="3">
      <b-skeleton-img no-aspect height="136px"></b-skeleton-img>
    </b-col>
    <b-col cols="3">
      <b-skeleton-img no-aspect height="136px"></b-skeleton-img>
    </b-col>
    <b-col cols="3">
      <b-skeleton-img no-aspect height="136px"></b-skeleton-img>
    </b-col>
  </div>
  <div v-else class="d-flex align-items-center flex-wrap">
    <essay-stats-card class="my-2" title="Total" :count="counters.total" />
    <essay-stats-card
      class="ml-3 ml-sm-3 my-2"
      title="Reviewed"
      :count="counters.reviewed"
      icon="check2"
      header-color="essay-stats-card-green"
      iconClass="essay-stats-card-icon-green"
      iconScale="1.5"
    />
    <essay-stats-card
      class="ml-0 ml-lg-3 my-2"
      title="Queued"
      :count="counters.queued"
      icon="arrow-repeat"
      header-color="essay-stats-card-cyan"
      iconClass="essay-stats-card-icon-cyan"
      iconScale="1.2"
    />
    <essay-stats-card
      class="ml-3 ml-sm-3 my-2"
      title="Ready for review"
      :count="counters.pending"
      icon="check2-circle"
      header-color="essay-stats-card-grey"
      iconClass="essay-stats-card-icon-grey"
      iconScale="1.5"
    />
  </div>
</template>

<script>
import { getCounters } from "@/api";
import EssayStatsCard from "../essay/essay_stats_card.vue";
export default {
  components: {
    EssayStatsCard,
  },
  data() {
    return {
      loading: true,
      counters: {
        total: 0,
        reviewed: 0,
        queued: 0,
        pending: 0,
      },
    };
  },

  created() {
    this.getCounters();
  },

  methods: {
    async getCounters() {
      try {
        this.loading = true;
        const { data: counters } = await getCounters();
        this.counters = counters;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
