<script>
import { getRubrics } from "../api";
import EmptyState from "../components/global/empty_state.vue";
import RubricCard from "../components/rubrics/rubric_card.vue";

export default {
  name: "RubricsView",
  components: {
    EmptyState,
    RubricCard,
  },
  data() {
    return {
      loading: true,
      error: "",
      rubrics: [],
    };
  },
  computed: {
    canCreateRubric() {
      return this.rubrics.length < 100;
    },
  },
  methods: {
    async fetchRubrics() {
      try {
        const { data } = await getRubrics();

        this.loading = false;
        this.error = false;

        this.rubrics = data;
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },
  },
  mounted() {
    this.fetchRubrics();
  },
};
</script>

<template>
  <b-container>
    <div v-if="rubrics.length > 0 && !loading" class="text-right svg-container">
      <img src="../assets/illustrations/rubrics-illustration.svg" alt="" />
    </div>
    <section>
      <b-row v-if="loading" class="mt-4">
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </b-col>
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </b-col>
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </b-col>
      </b-row>
      <b-row v-if="error">
        <b-alert variant="danger" show>{{ error }}</b-alert>
      </b-row>
      <template v-if="rubrics.length > 0">
        <b-row class="mb-3">
          <b-col lg="8" md="12">
            <h1>Rubrics</h1>
          </b-col>
          <b-col class="text-right create-rubric-column" lg="4" md="12">
            <b-button
              class="mr-2"
              v-if="canCreateRubric"
              variant="info"
              to="/rubric/new"
            >
              New rubric
            </b-button>
            <b-button v-if="canCreateRubric" variant="info" to="/upload-essays">
              Start grading
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            v-for="rubric in rubrics"
            :key="rubric.id"
            class="mb-3"
            lg="12"
            xl="4"
          >
            <rubric-card :rubric="rubric" />
          </b-col>
        </b-row>
      </template>

      <empty-state
        v-else-if="!loading && rubrics.length == 0"
        title="No rubrics have been created yet."
        description="We don't assume how you want to grade your essays. Create your own rubric to grade essays with."
        button-text="New Rubric"
        button-path="/rubric/new"
        class="mt-4"
      >
        <img
          src="../assets/illustrations/empty-state-rubrics.svg"
          alt="EssayGrader Empty State"
        />
      </empty-state>
    </section>
  </b-container>
</template>
