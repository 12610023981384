import api from "@/api/api";

export const createClass = (payload) => {
  return api.post("/class", payload);
};

export const getClasses = ({ page, limit } = {}) => {
  const params = {};

  if (page !== undefined) {
    params.page = page;
  }

  if (limit !== undefined) {
    params.limit = limit;
  }

  return api.get("/classes", { params });
};

export const getClass = (classId) => {
  return api.get("/class", { params: { classId } });
};

export const updateClass = (payload) => {
  return api.put("/class", payload);
};

export const deleteClass = (classId) => {
  return api.delete("/class", { data: { classId } });
};
