<script>
import RegisterForm from "../components/auth/register_form.vue";

export default {
  name: "RegisterView",
  components: {
    RegisterForm,
  },
};
</script>

<template>
  <b-container>
    <b-row>
      <b-col>
        <register-form />
      </b-col>
    </b-row>
  </b-container>
</template>
