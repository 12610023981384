<script>
import { nextTick } from "vue";
import { getClasses } from "../api";
import ClassCard from "../components/classes/class_card.vue";
import EmptyState from "../components/global/empty_state.vue";

export default {
  name: "ClassesView",
  components: {
    ClassCard,
    EmptyState,
  },
  data() {
    return {
      loading: true,
      error: "",
      classes: [],
      count: 0,
      limit: null,
      currentPage: 1,
      classDeleted: "",
    };
  },
  methods: {
    async fetchClasses() {
      await nextTick();

      try {
        const { data } = await getClasses({ page: this.currentPage });

        this.classes = data.classes;
        this.limit = data.limit;
        this.count = data.count;

        this.loading = false;
        this.error = false;
      } catch (error) {
        this.error = error.response?.data?.error;
        this.loading = false;
      }
    },
  },
  mounted() {
    // user was re-routed after deleting a class
    if (this.$route.query.deleted) {
      this.classDeleted = "Class was successfully deleted.";
    }

    this.fetchClasses();
  },
};
</script>

<template>
  <b-container>
    <div v-if="classes.length > 0 && !loading" class="text-right svg-container">
      <img src="../assets/illustrations/classes-illustration.svg" alt="" />
    </div>
    <section v-if="loading || error">
      <b-row v-if="loading" class="mt-4">
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
      </b-row>
      <b-row v-if="error">
        <b-col>
          <b-alert variant="danger" show>{{ error }}</b-alert>
        </b-col>
      </b-row>
    </section>
    <section>
      <template v-if="classes.length > 0">
        <b-row class="mb-3">
          <b-col v-if="classDeleted" lg="12">
            <b-alert variant="success" show>
              {{ classDeleted }}
            </b-alert>
          </b-col>

          <b-col lg="8" md="12">
            <h1>Classes</h1>
          </b-col>
          <b-col class="text-right create-rubric-column" lg="4" md="12">
            <b-button variant="info" to="/class/new"> New class </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-for="foundClass in classes" :key="foundClass.id" lg="4">
            <class-card :user-class="foundClass" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-center">
              <b-pagination
                v-model="currentPage"
                :total-rows="count"
                :per-page="limit"
                @change="fetchClasses"
              >
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </template>
      <empty-state
        v-else-if="!loading && classes.length == 0"
        class="mt-5"
        title="No classes have been created yet."
        description="Use classes to neatly organize your students essays."
        button-text="New class"
        button-path="/class/new"
      >
        <img
          src="../assets/illustrations/empty-state-classes.svg"
          alt="EssayGrader Empty State"
        />
      </empty-state>
    </section>
  </b-container>
</template>
