<script>
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import ClassForm from "@/components/classes/class_form.vue";

export default {
  name: "NewClassView",
  components: {
    Breadcrumbs,
    ClassForm,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Classes",
          href: "/classes",
        },
        {
          text: "New Class",
          active: true,
        },
      ];
    },
  },
};
</script>

<template>
  <b-container>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row>
        <b-col lg="6">
          <h1>New class</h1>
        </b-col>
      </b-row>
      <class-form />
    </section>
  </b-container>
</template>
