<script>
import LoginForm from "../components/auth/login_form.vue";

export default {
  name: "LoginView",
  components: {
    LoginForm,
  },
  computed: {
    isSessionExpired() {
      return this.$route.query.ref === "expired";
    },
  },
};
</script>

<template>
  <b-container>
    <b-row>
      <b-col>
        <b-alert v-if="isSessionExpired" variant="warning" show>
          Your log in session has expired, please log back in to get back to
          grading.
        </b-alert>

        <login-form />
      </b-col>
    </b-row>
  </b-container>
</template>
