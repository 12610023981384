<template>
  <b-container class="upload-essay-view">
    <section>
      <div>
        <b-row>
          <b-col>
            <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
            <h1 class="mb-2">Start grading</h1>

            <essay-submission-form @essaySubmitted="getFeedback" />
          </b-col>
        </b-row>
      </div>
    </section>
  </b-container>
</template>

<script>
import EssaySubmissionForm from "@/components/dashboard/essay_submission_form.vue";
import { feedback } from "@/api";

export default {
  name: "DashboardView",
  components: {
    EssaySubmissionForm,
  },
  data() {
    return {
      error: "",
      hasUpgraded: "",
    };
  },

  computed: {
    featureFlags() {
      return this.$store.state.featureFlags;
    },
  },

  methods: {
    async getFeedback(payload) {
      try {
        await feedback(payload);

        this.error = "";
        this.$router.push("/essays");
      } catch (error) {
        // error handled on the interceptors
        return;
      }
    },
  },
};
</script>
