import growthbook from "@/mixins/growthbook_client";
import openFeatureModalIfNeeded from "@/components/global/globalModals/openFeatureModalIfNeeded";

export default {
  methods: {
    async redirectToRoute(vm, user) {
      await growthbook.init({ streaming: true });
      growthbook.setAttributes({ email: user.email });

      const TDAInstructions = growthbook.isOn("tda-instructions");

      vm.$store.dispatch("setFeatureFlags", {
        TDAInstructions,
      });

      vm.$router.replace({ name: "dashboard" });

      openFeatureModalIfNeeded(vm);
    },
  },
};
