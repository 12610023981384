<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h1 class="mb-2">Monthly usage</h1>
        <hr />
      </b-col>
      <b-col v-if="usageLoading" cols="12">
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </b-col>
      <template v-else>
        <b-col xl="6" class="mb-4">
          <usage-card
            title="Essay submissions"
            :count="usage.essaysUsage.count"
            :remainder="usage.essaysUsage.remainder"
          />
        </b-col>
        <b-col xl="6">
          <usage-card
            title="Summary submissions"
            :count="usage.summaryUsage.count"
            :remainder="usage.summaryUsage.remainder"
          />
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import UsageCard from "@/components/dashboard/usage_card.vue";
import { getUsage } from "@/api";

export default {
  components: { UsageCard },

  data() {
    return {
      usageLoading: true,
      usage: {},
      hasUpgraded: "",
    };
  },

  async mounted() {
    try {
      const { data } = await getUsage();

      this.usage = data;
      this.usageLoading = false;
    } catch (error) {
      this.usageLoading = false;
      this.$bvToast.toast(error.response?.data?.error, {
        title: "Error",
        variant: "danger",
      });
    }
  },
};
</script>
