<script>
export default {
  name: "RubricScaleInput",
  props: {
    value: {
      type: Object,
      default: () => ({ value: "", scale: 4 }),
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showScale: {
      type: Boolean,
      required: false,
    },
    showDelete: {
      type: Boolean,
      required: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    isDescriptor: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isUsingAdvancedScale: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    updateValue(value) {
      this.$emit("updateValue", value);
    },

    updateScale(scale) {
      this.$emit("updateScale", parseInt(scale));
    },
  },

  computed: {
    scaleInputValid() {
      if (isNaN(this.value.scale)) return false;

      const scaleValue = parseInt(this.value.scale);
      if (!(scaleValue >= 0 && scaleValue <= 100)) return false;

      return null;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="d-flex justify-content-between flex-md-row flex-column rubric-wrapper align-items-end"
    >
      <b-button
        v-if="showDelete && isDescriptor"
        :disabled="isDisabled"
        class="delete-button"
        @click="$emit('delete')"
        variant="outline-secondary"
      >
        <b-icon icon="trash"></b-icon>
      </b-button>
      <b-form-group
        :class="`text-input ${!showDelete && isDescriptor && 'without-button'}`"
        :id="`scale-${index}-group`"
        :label-for="`scale-${index}-input`"
        :description="description"
      >
        <template #label v-if="label">
          {{ label }} <span class="red-text">*</span>
        </template>
        <component
          :is="textarea ? 'b-form-textarea' : 'b-form-input'"
          class="rubric-form-input-width"
          :value="value.value"
          @input="updateValue"
          :placeholder="placeholder"
          type="text"
          no-resize
          rows="3"
          max-rows="5"
        ></component>
      </b-form-group>

      <b-form-group
        v-if="showScale && !isUsingAdvancedScale"
        class="scale-input"
        :id="`scale-${index}-group`"
        :label-for="`scale-${index}-input`"
        description="1 - 6"
      >
        <template #label>Grade<span class="red-text">*</span> </template>
        <b-form-spinbutton
          :id="`criteria-${index}-scale-input`"
          :value="value.scale"
          @input="updateScale"
          min="1"
          max="6"
        ></b-form-spinbutton>
      </b-form-group>
      <b-form-group
        v-if="showScale && isUsingAdvancedScale"
        class="scale-input mt-1"
        :id="`scale-${index}-group`"
        :label-for="`scale-${index}-input`"
        :description="`0 - 100`"
      >
        <template #label>Grade<span class="red-text">*</span> </template>
        <b-form-input
          :id="`criteria-${index}-scale-input`"
          :value="value.scale"
          @input="updateScale"
          type="number"
          min="0"
          max="100"
          :state="scaleInputValid"
        ></b-form-input>
        <b-form-invalid-feedback>
          Enter a value between 0 and 100.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button
        v-if="showDelete && !isDescriptor"
        class="delete-button"
        @click="$emit('delete')"
        variant="outline-secondary"
      >
        <b-icon icon="trash"></b-icon>
      </b-button>
    </div>
    <slot />
  </div>
</template>

<style scoped lang="scss">
.rubric-wrapper {
  gap: 20px;

  .text-input {
    width: 100%;

    &.without-button {
      margin-left: 76px;
    }
  }

  .scale-input {
    flex-shrink: 0;
  }
}

.delete-button {
  margin-bottom: 39px;
}
</style>
