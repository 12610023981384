<script>
export default {
  name: "SubscriptionCancelUpgradeModal",
  props: {
    isLitePlan: {
      type: Boolean,
      require: true,
    },
    updateToLitePlan: {
      type: Function,
      require: true,
    },
    applyCoupon: {
      type: Function,
      require: true,
    },
  },
  computed: {
    title() {
      return this.isLitePlan
        ? "We don't want to see you go!"
        : "Would you like to switch to our Lite Plan.";
    },

    mainText() {
      return this.isLitePlan
        ? "You will lose access to your stored information, Class details, Custom Rubrics, AI Detector, and Essay Summaries. Instead, can we offer you 50% off on your subscription for the next 3 months?"
        : "We are sorry you haven't found the right plan yet. Switch to our Lite Plan for only $8.99 per month. Keep all your information, grade 75 essays and summarize 15 essays per month.";
    },
  },

  methods: {
    handleClick() {
      const handleFn = this.isLitePlan
        ? this.applyCoupon
        : this.updateToLitePlan;

      handleFn().then(() => {
        this.$bvModal.show("subscription_cancel_success_modal");
        this.$bvModal.hide("subscription_cancel_upgrade_modal");
      });
    },
  },
};
</script>

<template>
  <b-modal
    id="subscription_cancel_upgrade_modal"
    :title="title"
    size="lg"
    centered
    hide-footer
    no-stacking
  >
    <p>
      {{ mainText }}
    </p>

    <div class="d-flex justify-content-end">
      <b-button variant="outline-info" v-b-modal.subscription_cancel_modal>
        Cancel subscription
      </b-button>

      <b-button class="ml-3" variant="info" @click="handleClick"
        >{{ isLitePlan ? "Yes" : "Switch plan" }}
      </b-button>
    </div>
  </b-modal>
</template>
