<template>
  <div class="mt-5">
    <h1>Import from Google Classroom</h1>
    <b-card class="mt-5 py-1">
      <eg-stepper
        v-model="loading"
        finish-label="Grade"
        :stepsTitles="stepsTitles"
        @beforeChange="beforeChange"
        @finish="finishHandler"
      >
        <template #slot-1>
          <classroom-select-class
            v-model="selectedClassId"
            @loaded="loading = false"
          />
        </template>
        <template #slot-2>
          <classroom-select-assignment
            v-model="selectedAssignmentId"
            :class-id="selectedClassId"
            @loaded="loading = false"
          />
        </template>
        <template #slot-3>
          <classroom-select-submissions
            v-model="selectedSubmissionsIds"
            :class-id="selectedClassId"
            :assignment-id="selectedAssignmentId"
            @loaded="loading = false"
          />
          <b-checkbox
            class="export-to-gc"
            v-model="toBeExported"
            variant="info"
          >
            Export essay feedback automatically for Google Doc submissions
            <b-icon
              class="ml-2"
              variant="primary"
              v-b-popover.hover.top="
                'EssayGrader will edit Google Doc submissions by adding the feedback in a separate section.'
              "
              icon="question-circle"
            />
          </b-checkbox>
        </template>
        <template #slot-4>
          <classroom-select-rubric
            ref="classroomSelectRubric"
            v-model="selectedRubric"
            :selected-default-rubric="selectedDefaultRubric"
            @input-default-rubric="handleInputDefaultRubric"
            @loaded="loading = false"
          />
        </template>
        <template #slot-5>
          <classroom-select-details
            show-intensity
            :selectedRubric="selectedRubric"
            :selected-language-id="selectedLanguageId"
            :rubricIntensity="rubricIntensity"
            @input-language="selectedLanguageId = $event"
            @input-intensity="rubricIntensity = $event"
            @input-tda="tda = $event"
          />
        </template>
      </eg-stepper>
    </b-card>
  </div>
</template>

<script>
import { importClassroomSubmissions } from "@/api";
import EgStepper from "@/components/global/eg_stepper.vue";
import ClassroomSelectClass from "./classroom_select_class.vue";
import ClassroomSelectAssignment from "./classroom_select_assignment.vue";
import ClassroomSelectSubmissions from "./classroom_select_submissions.vue";
import ClassroomSelectRubric from "./classroom_select_rubric.vue";
import ClassroomSelectDetails from "./classroom_select_details.vue";
import errorHandler from "./errorHandler";

export default {
  name: "GoogleClassroomWizard",

  components: {
    EgStepper,
    ClassroomSelectClass,
    ClassroomSelectAssignment,
    ClassroomSelectSubmissions,
    ClassroomSelectRubric,
    ClassroomSelectDetails,
  },

  props: {
    selectedGoogleAccountId: {
      type: String,
    },
  },

  provide() {
    return {
      selectedGoogleAccountId: this.selectedGoogleAccountId,
    };
  },

  data() {
    return {
      loading: false,
      stepsTitles: [
        "Step one: Select a class",
        "Step two: Select assignment",
        "Step three: Select submissions",
        "Step four: Select rubric",
        "Step five: Grading details",
      ],
      toBeExported: false,
      selectedClassId: null,
      selectedAssignmentId: null,
      selectedSubmissionsIds: [],
      selectedDefaultRubric: {
        gradingScale: "letter",
        essayType: "persuasive",
        gradeLevel: "elementary",
      },
      selectedRubric: null,
      selectedLanguageId: null,
      rubricIntensity: "moderate",
      tda: {},
    };
  },

  computed: {
    selectedRubricId() {
      return this.selectedRubric?.id;
    },
  },

  methods: {
    handleInputDefaultRubric({ type, value }) {
      console.log({ type, value });

      this.selectedDefaultRubric[type] = value;
    },

    async beforeChange({ index, resolve, reject }) {
      switch (index) {
        case 0: {
          if (!this.selectedClassId) {
            reject();
            this.$bvToast.toast("Please select a class", {
              title: "Validation Error",
              variant: "danger",
            });
          } else {
            resolve();
          }
          break;
        }
        case 1:
          if (!this.selectedAssignmentId) {
            reject();
            this.$bvToast.toast("Please select an assignment", {
              title: "Validation Error",
              variant: "danger",
            });
          } else {
            resolve();
          }
          break;
        case 2:
          if (this.selectedSubmissionsIds.length === 0) {
            reject();
            this.$bvToast.toast("Please select submissions", {
              title: "Validation Error",
              variant: "danger",
            });
          } else {
            resolve();
          }
          break;

        case 3: {
          const isDone = await this.$refs.classroomSelectRubric.submit();

          if (isDone) {
            resolve();
          } else {
            reject();
            this.$bvToast.toast("Please select a rubric", {
              title: "Validation Error",
              variant: "danger",
            });
          }

          break;
        }

        default:
          resolve();
      }
    },

    async finishHandler(resolve) {
      const payload = {
        submissionsIds: this.selectedSubmissionsIds,
        intensity: this.rubricIntensity,
        LanguageId: this.selectedLanguageId,
        toBeExported: this.toBeExported,
      };

      if (this.selectedRubricId) {
        payload.RubricId = this.selectedRubricId;
      } else {
        payload.gradeLevel = this.selectedDefaultRubric.gradeLevel;
        payload.gradeScale = this.selectedDefaultRubric.gradeScale;
        payload.type = this.selectedDefaultRubric.essayType;
      }

      if (this.tda.sources) {
        payload.tdaSources = this.tda.sources;
      }

      if (this.tda.instructions) {
        payload.tdaInstructions = this.tda.instructions;
      }

      try {
        const { data } = await importClassroomSubmissions(
          this.selectedGoogleAccountId,
          this.selectedClassId,
          this.selectedAssignmentId,
          payload
        );

        const notImported = data.filter((item) => !item.imported);

        if (notImported.length > 0) {
          this.$root.$bvToast.toast(
            `Submissions were skipped for the following students
            because they were not in the Google Docs format.
            More formats will be supported at a future date.
            Students with skipped essays:
            ${notImported.map((item) => item.name).join(", ")}
            `,
            {
              title: "Some submissions skipped",
              variant: "danger",
              noAutoHide: true,
            }
          );
        } else {
          this.$root.$bvToast.toast(
            `Student assignments were imported successfully and are queued for grading.`,
            {
              title: "Import successful",
              variant: "success",
            }
          );
        }

        resolve();
        this.$router.push({ name: "essays" });
      } catch (error) {
        errorHandler({
          vm: this,
          error,
          title: "Import Error",
          message:
            "There was a problem import submissions from the classroom. Please try again",
        });
        resolve();
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding-left: 0;
  padding-right: 0;
}

.export-to-gc {
  margin: 0 12px;
}
</style>
