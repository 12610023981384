<template>
  <eg-dropdown :size="size" :text="value ? value.name : 'Select rubric'">
    <b-dropdown-text v-if="groupedRubrics.length === 0">
      No rubric have been created yet.
    </b-dropdown-text>
    <b-dropdown-group
      v-for="(rubrics, rubricType) in groupedRubrics"
      :key="rubricType"
      :header="`${rubricType} RUBRICS`"
    >
      <b-dropdown-item-button
        v-for="rubricData in rubrics"
        :key="rubricData.id"
        class="dropdown-item-wrapper"
        @click="() => selectRubric(rubricData)"
      >
        {{ rubricData.name }}
      </b-dropdown-item-button>
    </b-dropdown-group>
  </eg-dropdown>
</template>

<script>
import EgDropdown from "@/components/global/eg_dropdown";

export default {
  components: {
    EgDropdown,
  },

  props: {
    value: {
      type: Object,
    },
    rubrics: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
    },
  },

  computed: {
    groupedRubrics() {
      const groupedRubrics = this.rubrics.reduce((grouped, rubric) => {
        const rubricType = rubric.type.toUpperCase();

        if (!grouped[rubricType]) {
          grouped[rubricType] = [];
        }

        grouped[rubricType].push(rubric);

        return grouped;
      }, {});

      return groupedRubrics;
    },
  },

  methods: {
    selectRubric(rubric) {
      this.$emit("input", rubric);
    },
  },
};
</script>
