<script>
import SelectImport from "../components/dashboard/select_import.vue";

export default {
  name: "DashboardView",

  components: {
    SelectImport,
  },

  data() {
    return {
      hasUpgraded: "",
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  async mounted() {
    // user was re-routed after upgrading plans
    if (this.$route.query.upgraded || this.$route.query.downgraded) {
      const upgradedPlan = this.$route.query.upgraded;
      const downgradedPlan = this.$route.query.downgraded;
      const plan = upgradedPlan || downgradedPlan;
      this.hasUpgraded = `Congrats, you have successfully ${
        downgradedPlan ? "downgraded" : "upgraded"
      } to the ${plan} plan!`;
    }
  },
};
</script>

<template>
  <div>
    <b-container>
      <section>
        <b-row>
          <b-col>
            <b-alert v-if="hasUpgraded" variant="success" show>
              {{ hasUpgraded }}
            </b-alert>

            <header class="welcome-header">
              <h1 class="welcome-text">
                👋 Welcome, {{ user.firstName || user.email }}
              </h1>
            </header>
          </b-col>
        </b-row>
      </section>
    </b-container>

    <select-import></select-import>
  </div>
</template>
