<script>
export default {
  name: "SubscriptionCancelSuccessModal",
  props: {
    isLitePlan: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    mainText() {
      return this.isLitePlan
        ? "You monthly subscription price is now 50% off for next 3 months. Happy grading!"
        : "Congrats on moving to Lite Plan. You will now be able to retain your information, Grade 75 essays and summarize 15 essays per month.";
    },
  },
};
</script>

<template>
  <b-modal
    id="subscription_cancel_success_modal"
    size="lg"
    centered
    hide-footer
    no-stacking
  >
    <p>
      {{ mainText }}
    </p>

    <div class="d-flex justify-content-end">
      <b-button
        variant="info"
        @click="() => $bvModal.hide('subscription_cancel_success_modal')"
      >
        Okay
      </b-button>
    </div>
  </b-modal>
</template>
