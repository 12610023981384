<script>
import EssaySummarizeForm from "@/components/essay_summarize_form.vue";
import { getEssaySummary } from "../api";

export default {
  name: "SummarizeEssayView",
  components: { EssaySummarizeForm },
  data() {
    return {
      loading: false,
      error: "",
      summarizedEssay: null,
    };
  },
  methods: {
    async summarizeEssay(payload) {
      try {
        this.loading = true;

        const { data } = await getEssaySummary(payload);

        this.summarizedEssay = data.content;

        this.loading = false;
        this.error = "";
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    printEssaySummary() {
      window.print();
    },
  },
};
</script>

<template>
  <b-container>
    <div v-if="!summarizedEssay && !loading" class="text-right svg-container">
      <img src="../assets/illustrations/summarize-illustration.svg" alt="" />
    </div>
    <section>
      <b-row v-if="loading">
        <b-col md="3"></b-col>
        <b-col md="6" class="text-center">
          <b-spinner class="mb-4" style="width: 3rem; height: 3rem"></b-spinner>

          <h4>Summarizing essay...</h4>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>

          <template v-if="!summarizedEssay">
            <h1 class="mb-4">Summarize</h1>
          </template>
        </b-col>
        <b-col>
          <div v-if="!summarizedEssay">
            <p class="mb-2">Upload an essay to get a concise summary</p>
            <essay-summarize-form @essaySubmitted="summarizeEssay" />
          </div>

          <div v-else>
            <div class="d-flex justify-content-between mb-3">
              <h1>Essay summary</h1>
              <b-button @click="printEssaySummary"> Print summary </b-button>
            </div>

            <div id="essay-summary" v-html="summarizedEssay"></div>
          </div>
        </b-col>
        <b-col v-if="!summarizedEssay" cols="1"></b-col>
        <b-col v-if="!summarizedEssay" cols="6">
          <div class="help-box">
            <p class="font-rubik">How does it work?</p>
            <ul>
              <li>Detects and summarizes the most import information</li>
              <li>Analyzes unique phrases</li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
<style scoped lang="scss">
#essay-summary::v-deep {
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }

  /* Table border and spacing */
  #container table,
  th,
  td {
    border: 1px solid lavender;
  }

  /* Header styles */
  th {
    background-color: lavender;
    color: #4b0082; /* Indigo for contrast */
    padding: 10px;
    text-align: left;
  }

  /* Row styles */
  td {
    padding: 10px;
    background-color: #e6e6fa; /* Light lavender for rows */
    color: #333; /* Darker text color for readability */
  }

  /* Alternate row styling (zebra stripes) */
  tr:nth-child(even) {
    background-color: #f0f0f8;
  }

  /* Hover effect */
  tr:hover {
    background-color: #d8bfd8; /* Thistle color on hover */
    transition: background-color 0.3s ease;
  }

  /* Table caption */
  caption {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #4b0082;
    font-weight: bold;
  }
}
</style>
