<template>
  <b-container class="imports-google-classroom-view">
    <b-row class="justify-content-center align-items-center">
      <b-col cols="12" md="9" lg="8" xl="7">
        <import-google-classroom-connect
          v-if="!selectedGoogleAccount || !selectedGoogleAccount.isGCAvailable"
          @input="updateSelectedGoogleAccount"
        />
        <import-google-classroom-wizard
          v-if="
            selectedGoogleAccount &&
            selectedGoogleAccount.gid &&
            selectedGoogleAccount.isGCAvailable
          "
          :selected-google-account-id="selectedGoogleAccount.gid"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ImportGoogleClassroomWizard from "@/components/imports/google-classroom/classroom_wizard.vue";
import ImportGoogleClassroomConnect from "@/components/imports/google-classroom/classroom_connect.vue";

export default {
  components: {
    ImportGoogleClassroomWizard,
    ImportGoogleClassroomConnect,
  },

  data() {
    return {
      selectedGoogleAccount: null,
    };
  },

  methods: {
    updateSelectedGoogleAccount(account) {
      if (!account.isGCAvailable) {
        return this.$bvToast.toast(
          "Google Classroom permissions have expired or are not available for this account. Please try again and select all permissions.",
          {
            title: "Error",
            variant: "danger",
          }
        );
      }

      this.selectedGoogleAccount = account;
    },
  },
};
</script>
