export const criteriaChecker = ({ scaleType, criterias }) => {
  // ** TODO validate grading criteria **
  // Input should be text with no special characters
  // Input should not contain numbers

  let proceed = true;
  let error = "";

  const allFieldNotEmpty = criterias.every((criteria) => {
    if (scaleType === "Default") {
      return criteria?.criteriaGrades?.[0]?.description;
    }

    if (scaleType === "Simple" || scaleType === "Advanced") {
      return criteria?.criteriaGrades?.every(
        (grade) => grade.description && grade.maxGrade >= 0
      );
    }

    return true;
  });

  if (!allFieldNotEmpty) {
    proceed = false;
    error =
      "Error: You cannot leave a field blank. Remove unnecessary fields or fill in all fields.";
  }

  return { canCreateRubric: proceed, rubricError: error };
};

export const MAX_COUNT_WORDS = 2000;
const getWordCount = (str) =>
  str.trim().length ? str.trim().split(" ").length : 0;
export const getWordsCountOfAllText = (criteriasArr) => {
  return criteriasArr.reduce((acc, criteria) => {
    return (
      acc +
      getWordCount(criteria.point) +
      criteria.scales.reduce((acc, scale) => {
        return acc + getWordCount(scale.description);
      }, 0)
    );
  }, 0);
};
