import Quill from "quill";
import Delta from "quill-delta";

let Inline = Quill.import("blots/inline");
const Parchment = Quill.imports.parchment;

const ClassParchment = new Parchment.Attributor("class", "class", {
  scope: Parchment.Scope.BLOCK,
});

Quill.register(ClassParchment);

class SpanBlot extends Inline {
  static create(value) {
    let node = super.create(true);
    node.classList.add(value);

    return node;
  }
}

SpanBlot.blotName = "span-blot";
SpanBlot.tagName = "SPAN";
Quill.register(SpanBlot);

export const addMatchers = (quill) => {
  quill.clipboard.addMatcher(`p span`, (node, delta) => {
    const blotClasses = [...node.classList];
    const attributes = {
      "span-blot": blotClasses,
    };

    const newDelta = delta.compose(
      new Delta().retain(delta.length(), attributes)
    );

    return newDelta;
  });
};
