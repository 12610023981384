<template>
  <div v-if="loading">
    <b-skeleton width="25%"></b-skeleton>
    <b-skeleton type="input"></b-skeleton>
  </div>
  <eg-form-group v-else label="Assignment" required>
    <eg-dropdown
      :text="
        selectedAssignment ? selectedAssignment.title : 'Select assignment'
      "
    >
      <b-dropdown-text v-if="assignments.length === 0">
        The selected classroom has no assignments. Choose a different classroom
        to continue.
      </b-dropdown-text>
      <b-dropdown-item-button
        v-for="assignmentData in assignments"
        :key="assignmentData.id"
        class="dropdown-item-wrapper"
        @click="() => selectAssignment(assignmentData)"
      >
        {{ assignmentData.title }}
      </b-dropdown-item-button>
    </eg-dropdown>
  </eg-form-group>
</template>

<script>
import { getClassroomAssignments } from "@/api";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import EgDropdown from "@/components/global/eg_dropdown.vue";
import errorHandler from "./errorHandler";

export default {
  components: {
    EgFormGroup,
    EgDropdown,
  },

  props: {
    value: {
      type: String,
    },

    classId: {
      type: String,
      required: true,
    },
  },

  inject: ["selectedGoogleAccountId"],

  data() {
    return {
      loading: true,
      selectedAssignment: null,
      assignments: [],
    };
  },
  methods: {
    selectAssignment(assignmentData) {
      this.selectedAssignment = assignmentData;
      this.$emit("input", assignmentData.id);
    },

    async init() {
      try {
        this.loading = true;
        const { data } = await getClassroomAssignments(
          this.selectedGoogleAccountId,
          this.classId
        );
        this.assignments = data.assignments;

        let selectedAssignment = (this.assignments || [])[0];

        if (this.value) {
          const assignmentData = this.assignments.find(
            (c) => c.id === this.value
          );

          if (assignmentData) {
            selectedAssignment = assignmentData;
          }
        }

        selectedAssignment && this.selectAssignment(selectedAssignment);
        this.loading = false;
        this.$emit("loaded");
      } catch (error) {
        this.loading = false;
        this.$emit("loaded");
        errorHandler({
          vm: this,
          error,
          message:
            "There was a problem fetching assignments for the classroom. Please try again",
        });
      }
    },
  },

  created() {
    this.init();
  },
};
</script>
