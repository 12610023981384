<template>
  <eg-form-group
    id="upload-type-input-group"
    :label="label"
    :description="description"
    label-for="upload-type-input"
    :required="required"
  >
    <template #description>
      <slot name="description"></slot>
    </template>
    <div class="d-flex mb-2">
      <b-form-radio
        v-if="availableVariants.includes('files')"
        :name="`upload-radio-${_uid}`"
        value="files"
        class="mr-3"
        :checked="type"
        @input="changeType"
      >
        Select files
      </b-form-radio>
      <b-form-radio
        v-if="availableVariants.includes('text')"
        :name="`upload-radio-${_uid}`"
        value="text"
        class="mr-3"
        :checked="type"
        @input="changeType"
      >
        Enter text
      </b-form-radio>
    </div>
    <uppy-dashboard
      v-if="showFiles"
      ref="uppyDashboard"
      v-model="bulkFiles"
      :accept-files="acceptFiles"
      :max-number-of-files="maxNumberOfFiles"
    ></uppy-dashboard>
    <b-form-textarea
      v-if="showTextarea"
      id="textarea"
      v-model="textContent"
      placeholder="Paste the text here"
      :rows="rows"
      max-rows="24"
    ></b-form-textarea>
  </eg-form-group>
</template>

<script>
import EgFormGroup from "@/components/global/eg_form_group.vue";
import UppyDashboard from "./uppy_dashboard.vue";

export default {
  components: {
    EgFormGroup,
    UppyDashboard,
  },

  props: {
    label: {
      type: String,
      default: "Upload",
    },

    description: {
      type: String,
      default: "",
    },

    required: {
      type: Boolean,
      default: false,
    },

    rows: {
      type: Number,
      default: 12,
    },

    availableVariants: {
      type: Array,
      default: () => ["files", "text"],
    },

    acceptFiles: {
      type: Array,
      default: () => [".pdf", ".txt", ".docx"],
    },

    value: {
      type: String,
      default: "files",
    },

    maxNumberOfFiles: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      type: "",
      textContent: "",
      singleFile: null,
      bulkFiles: [],
    };
  },

  watch: {
    textContent() {
      this.$emit("input-text", this.textContent);
    },

    singleFile() {
      this.$emit("input-files", [this.singleFile]);
    },

    bulkFiles() {
      this.$emit("input-files", this.bulkFiles);
    },

    // this watcher is used to ensure a user
    // cannot upload a file in form, switch to text
    // and upload text content and make call to API
    // only one type of data can be sent up
    value() {
      this.type = this.value;

      if (this.value === "text") {
        this.singleFile = null;
        this.bulkFiles = [];
      } else {
        this.textContent = "";
      }
    },
  },

  computed: {
    canBulkUpload() {
      return this.$store.getters.userIsNotPausedAndPlanMoreThenPro;
    },

    showFiles() {
      return this.type === "files";
    },

    showTextarea() {
      return this.type === "text";
    },
  },

  created() {
    this.type = this.value;
  },

  methods: {
    async upload() {
      if (this.$refs.uppyDashboard) {
        await this.$refs.uppyDashboard.upload();
      }
    },

    changeType(type) {
      this.type = type;
      this.$emit("input", type);
    },
  },
};
</script>
