<script>
export default {
  name: "RubricPreviewTable",
  props: {
    scaleType: {
      type: String,
      required: true,
      validator: (value) => {
        return ["Default", "Simple", "Advanced"].includes(value);
      },
    },
    name: {
      type: String,
      required: true,
    },
    criterias: {
      type: Array,
      required: true,
    },
  },

  computed: {
    isAdvanced() {
      return this.scaleType === "Advanced";
    },

    computedCriterias() {
      if (!this.isAdvanced) {
        return this.criterias.slice().sort((a, b) => b.scale - a.scale);
      }

      return this.criterias.reduce((acc, criteria) => {
        const scales = criteria.scales
          .slice()
          .sort((a, b) => b.scale - a.scale);
        acc.push({
          criteriaName: criteria.point,
          descriptionsCount: scales.length,
          description: scales[0].description,
          scale: scales[0].scale,
        });

        scales.slice(1).forEach((scale) => {
          acc.push({
            description: scale.description,
            scale: scale.scale,
          });
        });

        return acc;
      }, []);
    },
  },
};
</script>

<template>
  <div>
    <b-table-simple bordered responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th :colspan="isAdvanced ? 3 : 2" class="text-center">
            {{ name }}
          </b-th>
        </b-tr>
        <b-tr>
          <b-th>Grading Criteria</b-th>
          <b-th v-if="isAdvanced">Criteria Descriptor</b-th>
          <b-th>Grade</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="isAdvanced">
          <b-tr v-for="(criteria, index) in computedCriterias" :key="index">
            <b-td
              v-if="criteria.criteriaName"
              :rowspan="criteria.descriptionsCount"
            >
              {{ criteria.criteriaName }}
            </b-td>
            <b-td>
              <pre class="description">{{ criteria.description }}</pre>
            </b-td>
            <b-td>{{ criteria.scale }}</b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr v-for="(criteria, index) in computedCriterias" :key="index">
            <b-td>{{ criteria.point }}</b-td>
            <b-td>{{ criteria.scale }}</b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<style lang="scss" scoped>
.description {
  font-family: "Roboto", sans-serif;
  white-space: pre-line;
}
</style>
