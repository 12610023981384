export default ({ vm, error, text = "", title = "Error" }) => {
  if (error.isAxiosError) {
    switch (error.response.data.code) {
      case "missedPermissions":
      case "badGoogleResponse":
        return;

      default:
        break;
    }
  } else {
    text = error.message;
  }

  vm.$bvToast.toast(text, {
    title: title,
    variant: "danger",
  });
};
