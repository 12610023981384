import { nextTick } from "vue";

import { getAllEssays } from "../api";

export default {
  data() {
    return {
      essays: [],
      limit: null,
      count: 0,
      queuedCount: 0,
      sortBy: "createdAt",
      sortDesc: true,
      defaultFilters: {},
      filters: {
        name: "",
        ClassId: null,
        RubricId: null,
      },
      tableLoading: true,
    };
  },

  computed: {
    isFiltered() {
      return Object.keys(this.filters).some((key) => this.filters[key]);
    },
  },

  methods: {
    async fetchEssays(ctx = {}) {
      const { sortBy, filters } = ctx;

      if (sortBy) {
        this.sortDesc = this.sortBy === sortBy ? !this.sortDesc : true;
        this.sortBy = sortBy;
      }

      if (filters) {
        this.filters =
          Object.keys(filters).length > 0
            ? { ...this.filters, ...filters }
            : filters;
        this.currentPage = 1;
      }

      // QUESTION: Why do we need this?
      await nextTick();

      this.tableLoading = true;

      try {
        const { data } = await getAllEssays({
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filters: { ...this.filters, ...this.defaultFilters },
        });

        this.essays = data.rows;
        this.limit = data.limit;
        this.count = data.count;
        this.queuedCount = data.queuedCount || 0;

        this.error = "";
        this.tableLoading = false;
      } catch (error) {
        this.tableLoading = false;
        this.error = error.response?.data?.error;
      }
    },
  },
};
