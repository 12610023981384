<script>
import { createClass, updateClass } from "../../api";

export default {
  name: "ClassForm",
  props: {
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
    classId: {
      type: Number,
      required: false,
      default: 0,
    },
    className: {
      type: String,
      required: false,
      default: "",
    },
    classDescription: {
      type: String,
      required: false,
      default: "",
    },
    classSize: {
      type: String,
      required: false,
      default: "",
    },
    buttonText: {
      type: String,
      required: false,
      default: "Create class",
    },
  },
  data() {
    return {
      error: "",
      name: "",
      description: "",
      size: "",
    };
  },
  methods: {
    async submitClass() {
      try {
        this.error = "";

        if (!this.name) {
          this.error = "The class needs a name.";
          return;
        }

        const classData = {
          name: this.name,
          description: this.description,
          size: this.size,
        };

        if (this.editing) {
          await updateClass({ ...classData, classId: this.classId });
        } else {
          await createClass({ ...classData });
        }

        this.$router.push("/classes");
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },
  },
  mounted() {
    if (this.editing) {
      this.name = this.className;
      this.description = this.classDescription;
      this.size = this.classSize;
    }
  },
};
</script>

<template>
  <b-row>
    <b-col lg="10">
      <b-alert v-if="error" variant="danger" class="mb-2" show>
        {{ error }}
      </b-alert>
      <b-form>
        <b-form-group id="name-group" label-for="name-input">
          <template #label>
            Class name <span class="red-text">*</span>
          </template>
          <b-form-input
            id="name-input"
            v-model="name"
            placeholder="Enter name"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="description-group"
          label="Description"
          label-for="description-textarea"
        >
          <b-form-textarea
            id="description-textarea"
            v-model="description"
            placeholder="Enter description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="size-group"
          label="Number of students"
          label-for="size-input"
        >
          <b-form-input
            id="size-input"
            v-model="size"
            placeholder="Enter number"
            type="text"
          ></b-form-input>
        </b-form-group>
      </b-form>

      <div class="text-right mt-4">
        <b-button to="/classes" class="mr-2">Cancel</b-button>
        <b-button type="submit" variant="info" @click="submitClass">
          {{ buttonText }}
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>
