<script>
import ResetPasswordV2Form from "../components/auth/reset_password_v2_form.vue";

export default {
  name: "ResetPasswordV2View",
  components: {
    ResetPasswordV2Form,
  },
};
</script>

<template>
  <b-container>
    <section>
      <b-row>
        <b-col>
          <a href="/"
            ><img src="../assets/auth-logo.png" alt="EssayGrader Logo"
          /></a>
          <hr />
          <h1 class="mb-4 purple-text">Reset Password</h1>

          <reset-password-v2-form />
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
