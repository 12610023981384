<script>
import { createRubric } from "../api";
import Breadcrumbs from "../components/global/breadcrumbs.vue";
import RubricForm from "../components/rubrics/rubric_form.vue";
import confetti from "canvas-confetti";

var count = 600;
var defaults = {
  origin: { y: 0.8 },
};

function fire(particleRatio, opts) {
  confetti({
    ...defaults,
    ...opts,
    particleCount: Math.floor(count * particleRatio),
  });
}

export default {
  name: "NewRubricView",
  components: {
    Breadcrumbs,
    RubricForm,
  },
  data() {
    return {
      loading: true,
      error: "",
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Rubrics",
          href: "/rubrics",
        },
        {
          text: "New",
          active: true,
        },
      ];
    },
  },
  methods: {
    fireConfetti() {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    },
    async createRubric(rubricData) {
      try {
        await createRubric(rubricData);

        if (rubricData.type === "Advanced") {
          this.fireConfetti();
        }

        this.$router.push("/rubrics");
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },
  },
};
</script>

<template>
  <b-container>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row class="mb-3">
        <b-col v-if="error" cols="12">
          <b-alert variant="danger" class="mb-4" show>
            {{ error }}
          </b-alert>
        </b-col>
        <b-col lg="12">
          <h1 class="purple-text">New Rubric</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <rubric-form @rubricSubmitted="createRubric" />
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
