<script>
import upgradePlanMixin from "../../mixins/upgradePlan";

export default {
  name: "GlobalNavbar",
  mixins: [
    /*
    this.upgradeHandler
    */
    upgradePlanMixin,
  ],
  data() {
    return {
      coupon: "20DISC",
    };
  },
  computed: {
    showAiDetectorNavItem() {
      return this.$store.getters.userIsNotPausedAndPlanMoreThenPro;
    },
    basicPlan() {
      return this.$store.getters.basicPlan;
    },
    litePlan() {
      return this.$store.getters.litePlan;
    },
    manualCustomer() {
      return this.$store.getters.manualCustomer;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
};
</script>

<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-brand to="/">
      <img src="../../assets/navbar-logo.png" alt="Essay Grader" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav
        class="d-flex align-items-xl-center align-items-lg-center align-items-sm-start"
      >
        <b-nav-item to="/essays" class="mr-3">
          <b-icon icon="stack"></b-icon> Essays
        </b-nav-item>
        <b-nav-item to="/classes" class="mr-3">
          <b-icon icon="people-fill"></b-icon> Classes
        </b-nav-item>
        <b-nav-item to="/rubrics" class="mr-3">
          <b-icon icon="grid3x3"></b-icon> Rubrics
        </b-nav-item>
        <b-nav-item to="/summarize" class="mr-3">
          <b-icon icon="stars"></b-icon> Summarize
        </b-nav-item>
        <b-nav-item v-if="showAiDetectorNavItem" to="/ai-detector" class="mr-3">
          <b-icon icon="upc-scan"></b-icon> AI Detector
        </b-nav-item>
        <b-nav-item v-if="!manualCustomer && (basicPlan || litePlan)">
          <b-button
            variant="yellow"
            class="text-date"
            @click="upgradeHandler('pro', false, coupon)"
          >
            Upgrade to Pro with coupon "20DISC" and get 20% off
          </b-button>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template #button-content>
            <b-icon icon="person-circle"></b-icon>
          </template>
          <b-dropdown-item to="/account">Account</b-dropdown-item>
          <b-dropdown-item to="/support">Support</b-dropdown-item>
          <b-dropdown-item
            href="https://forms.gle/SZ7n3khm5iSRbMii8"
            target="_blank"
          >
            Feedback
          </b-dropdown-item>
          <b-dropdown-item @click="() => $bvModal.show('new-features-modal')">
            New Features
          </b-dropdown-item>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
