<script>
import { getRubrics, getClasses } from "../../api";
import RubricIntensityRadioGroup from "../rubrics/rubric_intensity_radio_group.vue";
import EgFormGroup from "../global/eg_form_group.vue";
import RubricDefault from "@/components/rubrics/rubric_default.vue";
import EssayLanguageSelect from "@/components/dashboard/essay_language_select.vue";
import AdditionalAssignment from "./additional_assignment.vue";
import UploadContentArea from "./upload_content_area.vue";

const getAdditionalPayload = ({ name, content, fileUrl }) => {
  const additionalPayload = {
    name,
  };

  if (content) {
    additionalPayload.content = content;
  }

  if (fileUrl) {
    additionalPayload.fileUrl = fileUrl;
  }

  return additionalPayload;
};

export default {
  name: "SubmissionForm",
  components: {
    AdditionalAssignment,
    EssayLanguageSelect,
    RubricDefault,
    EgFormGroup,
    RubricIntensityRadioGroup,
    UploadContentArea,
  },
  data() {
    return {
      name: "",
      essayContents: "",
      uploadType: "files",
      files: [],
      loading: false,
      rubricIntensity: null,
      customRubricId: null,
      selectedLanguageId: null,
      rubricOptions: [
        {
          value: "Default",
          text: "Default",
        },
        {
          value: "Custom",
          text: "Custom",
        },
      ],
      rubricsLoading: false,
      rubrics: [],
      classOptions: [{ value: null, text: "Select class" }],
      selectedClass: null,
      classes: [],
      languages: [],
      classesLoading: false,
      tda: {},
    };
  },

  methods: {
    resetForm() {
      this.files = [];
    },

    showErrorToast(message) {
      this.$bvToast.toast(message, {
        title: "Error",
        variant: "danger",
      });
    },

    async onSubmit(e) {
      e.preventDefault();

      let payload = {
        name: this.name,
        gradeLevel: this.gradeLevel,
        type: this.essayType,
        LanguageId: this.selectedLanguageId,
      };

      if (this.essayContents) {
        payload.content = this.essayContents;
      }

      if (this.selectedClass) {
        payload.ClassId = this.selectedClass;
      }

      if (this.rubricIntensity) {
        payload.intensity = this.rubricIntensity;
      }

      if (this.useCustomRubrics && this.hasCustomRubrics) {
        payload = {
          ...payload,
          gradeLevel: "custom",
          type: "custom",
          RubricId: this.customRubricId,
        };
      }

      if (this.isAdvancedRubric && this.tda.sources) {
        payload.tdaSources = this.tda.sources;
      }

      if (this.isAdvancedRubric && this.tda.instructions) {
        payload.tdaInstructions = this.tda.instructions;
      }

      try {
        this.loading = true;
        await this.$refs.uploadArea.upload();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showErrorToast(error.response?.data?.error);

        return;
      }

      if (this.canSubmit) {
        // uploading bulk essay files
        if (this.files.length > 1) {
          try {
            this.loading = true;

            const bulkPayload = this.files.map((file) => {
              const additionalPayload = getAdditionalPayload(file);

              return {
                ...payload,
                ...additionalPayload,
              };
            });

            this.$emit("essaySubmitted", bulkPayload);

            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.showErrorToast(error.response?.data?.error);
          }
        }

        // uploading single essay file
        if (this.files.length === 1) {
          try {
            this.loading = true;

            const additionalPayload = getAdditionalPayload(this.files[0]);

            this.loading = false;

            this.$emit("essaySubmitted", [
              { ...payload, ...additionalPayload },
            ]);
          } catch (error) {
            this.loading = false;
            this.showErrorToast(error.response?.data?.error);
          }
        }

        // uploading plain text essay
        if (this.essayContents) {
          this.$emit("essaySubmitted", [payload]);
        }
      } else {
        return this.showErrorToast(
          "Error: You are missing form data, ensure that you have provided an essay name and the essay contents."
        );
      }

      this.resetForm();
    },

    async fetchRubrics() {
      if (this.useCustomRubrics) {
        try {
          this.rubricsLoading = true;

          const { data } = await getRubrics();

          this.rubricsLoading = false;

          this.rubrics = data;
          this.customRubricId =
            this.groupedRubrics?.["Advanced"]?.[0]?.id ||
            this.groupedRubrics?.["Simple"]?.[0]?.id ||
            this.rubrics?.[0]?.id;
        } catch (error) {
          this.rubricsLoading = false;
          this.showErrorToast(error.response?.data?.error);
        }
      }
    },

    async fetchClasses() {
      try {
        this.classesLoading = true;

        const { data } = await getClasses({ limit: 1000 });

        this.classes = data.classes;

        this.classesLoading = false;

        if (this.classes.length > 0) {
          this.classes.forEach((foundClass) => {
            this.classOptions.push({
              value: foundClass.id,
              text: foundClass.name,
            });
          });
        } else {
          this.classOptions.push({
            value: null,
            text: "No classes created yet",
            disabled: true,
          });
        }
      } catch (error) {
        this.classesLoading = false;
        this.showErrorToast(error.response?.data?.error);
      }
    },

    inputDefaultHandler({ type, value }) {
      this[type] = value;
    },
  },
  computed: {
    basicPlan() {
      return this.$store.getters.basicPlan;
    },
    litePlan() {
      return this.$store.getters.litePlan;
    },
    canNotUseAdvancedRubric() {
      const isFreeOrLitePlan = this.basicPlan || this.litePlan;

      return this.isAdvancedRubric && isFreeOrLitePlan;
    },
    selectedRubric() {
      return this.rubrics.find((rubric) => {
        return rubric.id === this.customRubricId;
      });
    },
    isAdvancedRubric() {
      if (!this.useCustomRubrics) {
        return false;
      }

      const isAdvancedRubric = (this.selectedRubric || {}).type === "Advanced";

      return isAdvancedRubric;
    },

    isFileUpload() {
      return this.uploadType === "files";
    },

    canSubmit() {
      const canSubmit =
        this.essayContents || this.essayFile || this.files.length > 0;

      // if file uploading, essay does not require a name
      if (this.isFileUpload) {
        return canSubmit;
      }

      return this.name && canSubmit;
    },
    rubricType: {
      get() {
        let rubricType = this.$store.state.rubricType;

        return rubricType;
      },
      set(value) {
        this.$store.dispatch("setRubricType", value);
      },
    },
    useDefaultRubrics() {
      return this.rubricType.toLowerCase() === "default";
    },
    useCustomRubrics() {
      const lowerCaseRubricType = this.rubricType.toLowerCase();

      return lowerCaseRubricType === "custom";
    },
    hasCustomRubrics() {
      return this.rubrics?.length > 0 || false;
    },
    groupedRubrics() {
      return this.rubrics.reduce((acc, rubric) => {
        const type = rubric.type;

        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(rubric);

        return acc;
      }, {});
    },
    canBulkUpload() {
      return this.$store.getters.userIsNotPausedAndPlanMoreThenPro;
    },
    gradeLevel: {
      get() {
        return this.$store.state.gradeLevel;
      },
      set(gradeLevel) {
        this.$store.dispatch("setGradeLevel", gradeLevel);
      },
    },
    essayType: {
      get() {
        return this.$store.state.essayType;
      },
      set(essayType) {
        this.$store.dispatch("setEssayType", essayType);
      },
    },
  },
  watch: {
    selectedRubric() {
      this.rubricIntensity = this?.selectedRubric?.intensity || "moderate";
    },
  },
  mounted() {
    this.fetchRubrics();
    this.fetchClasses();
  },
};
</script>

<template>
  <div class="box">
    <b-form @submit="onSubmit" class="essay-submission-form">
      <div class="rubrics-select-wrapper">
        <eg-form-group
          label="Rubric type"
          id="rubric-group"
          label-for="rubric-select"
          required
        >
          <b-form-select
            id="rubric-select"
            v-model="rubricType"
            :options="rubricOptions"
            @input="fetchRubrics"
          ></b-form-select>
        </eg-form-group>

        <rubric-default
          v-if="useDefaultRubrics"
          :gradeLevel="gradeLevel"
          :essayType="essayType"
          @input="inputDefaultHandler"
        />

        <div v-if="useCustomRubrics">
          <div v-if="rubricsLoading" class="mt-3">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="75%"></b-skeleton>
            <b-skeleton animation="wave" width="65%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="45%"></b-skeleton>
            <b-skeleton animation="wave" width="35%"></b-skeleton>
          </div>

          <template v-else>
            <template v-if="hasCustomRubrics">
              <b-form-group
                v-if="groupedRubrics?.['Advanced']?.length"
                label="Advanced rubrics"
              >
                <template>
                  <b-form-radio
                    v-for="rubric in groupedRubrics['Advanced']"
                    :key="rubric.id"
                    v-model="customRubricId"
                    name="custom-rubric"
                    :value="rubric.id"
                  >
                    {{ rubric.name }}
                  </b-form-radio>
                </template>
              </b-form-group>
              <b-form-group
                v-if="groupedRubrics?.['Simple']?.length"
                label="Simple rubrics"
              >
                <template>
                  <b-form-radio
                    v-for="rubric in groupedRubrics['Simple']"
                    :key="rubric.id"
                    v-model="customRubricId"
                    name="custom-rubric"
                    :value="rubric.id"
                  >
                    {{ rubric.name }}
                  </b-form-radio>
                </template>
              </b-form-group>
              <b-form-group
                v-if="groupedRubrics?.['Default']?.length"
                label="Legacy rubrics"
              >
                <template>
                  <b-form-radio
                    v-for="rubric in groupedRubrics['Default']"
                    :key="rubric.id"
                    v-model="customRubricId"
                    name="custom-rubric"
                    :value="rubric.id"
                  >
                    {{ rubric.name }}
                  </b-form-radio>
                </template>
              </b-form-group>
            </template>
            <p v-else>
              You have not defined any {{ rubricType }} rubrics yet. Please
              <router-link to="/rubrics">create a rubric</router-link> or use
              our default rubrics.
            </p>
          </template>
        </div>
      </div>
      <div v-if="!loading && canNotUseAdvancedRubric" class="essay-contents">
        <b-alert variant="warning" show>
          Upgrade to a Pro or Premium plan to grade with Advanced Rubrics.
          <b-button variant="info" class="mt-2 essay-submit-btn" to="/account">
            Click here to Upgrade
          </b-button>
        </b-alert>
      </div>
      <div v-else class="essay-contents">
        <b-form-group id="class-group" label="Class" label-for="class-select">
          <b-form-select
            id="class-select"
            v-model="selectedClass"
            :options="classOptions"
          ></b-form-select>
        </b-form-group>

        <essay-language-select v-model="selectedLanguageId" />

        <rubric-intensity-radio-group
          v-if="isAdvancedRubric"
          horizontal
          v-model="rubricIntensity"
        />

        <upload-content-area
          ref="uploadArea"
          class="upload-content-area"
          label="Upload essay(s)"
          required
          v-model="uploadType"
          :max-number-of-files="canBulkUpload ? 1000 : 1"
          @input-files="files = $event"
          @input-text="essayContents = $event"
        >
          <template #description>
            <div v-if="!canBulkUpload">
              <b-button
                to="/accounts"
                variant="link"
                class="px-0 py-0 account-link"
              >
                Upgrade to the Pro or Premium plan
              </b-button>
              to upload more than 1 essay at a time.
            </div>
            <div v-else class="muted-text">
              Supported file types: .docx, .txt and .pdf
            </div>
          </template>
        </upload-content-area>

        <eg-form-group
          id="name-input-group"
          v-if="!isFileUpload"
          label="Name"
          label-for="name-input"
          required
        >
          <b-form-input
            id="name-input"
            v-model="name"
            type="text"
            placeholder="Enter the essay name"
          ></b-form-input>
        </eg-form-group>

        <additional-assignment v-if="isAdvancedRubric" v-model="tda" />

        <b-button
          type="submit"
          variant="info"
          :disabled="loading"
          class="w-25 mt-2 float-right essay-submit-btn"
        >
          Grade essay
        </b-button>

        <p v-if="loading">Parsing files...</p>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>
.account-link {
  font-size: 12.8px;
  border: none;
  vertical-align: baseline;
}

.upload-content-area::v-deep {
  .uppy-Dashboard {
    height: 325px;
  }
}
</style>
