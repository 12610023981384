<script>
export default {
  name: "CanceledView",
};
</script>

<template>
  <b-container>
    <section>
      <b-row>
        <b-col>
          <b-alert variant="danger" show>
            We could not successfully charge your card. Ensure all your card
            information is up-to-date. Please try again.
          </b-alert>
          <p>
            We are looking forward to doing business with you, but the payment
            you provided could not be charged. Please double check all your
            information and try to upgrade your account again. If you are still
            having problems shoot us an email
            <a href="mailto:hello@essaygrader.ai">hello@essaygrader.ai</a>
          </p>
          <b-button to="/account" variant="info">Upgrade</b-button>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
