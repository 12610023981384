<script>
import ClientDisabledBanner from "./components/global/client_disabled_banner.vue";
import growthbookClient from "@/mixins/growthbook_client";
import GlobalModals from "./components/global/globalModals/global_modals.vue";
import MaintenanceBanner from "./components/global/maintenance_banner.vue";
import Navbar from "./components/global/navbar.vue";
import PausedBanner from "./components/global/paused_banner.vue";

export default {
  components: {
    ClientDisabledBanner,
    MaintenanceBanner,
    Navbar,
    PausedBanner,
    GlobalModals,
  },

  data() {
    return {
      webClientDisabled: false,
      webClientDisabledMessage: undefined,
    };
  },

  computed: {
    hideNavbar() {
      return this.$route?.meta?.hideNavbar;
    },
  },
  async mounted() {
    await growthbookClient.init({ streaming: true });
    this.webClientDisabledMessage = growthbookClient.getFeatureValue(
      "web-client-disabled-message"
    );
    this.webClientDisabled = !!this.webClientDisabledMessage;
  },
};
</script>
<template>
  <div id="app">
    <div v-if="webClientDisabled">
      <client-disabled-banner
        :clientDisabledMessage="webClientDisabledMessage"
      />
    </div>
    <div v-else>
      <navbar v-if="!hideNavbar" />
      <maintenance-banner />
      <paused-banner />
      <router-view />
      <global-modals />
    </div>
  </div>
</template>
