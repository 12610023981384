<script>
import { forgotPassword } from "../../api";

export default {
  name: "ForgotPasswordForm",
  data() {
    return {
      email: "",
      errorMessage: "",
      success: false,
    };
  },
  methods: {
    async onSubmit() {
      const forgotPasswordData = {
        email: this.email,
      };

      try {
        await forgotPassword(forgotPasswordData);

        this.success = true;
        this.errorMessage = "";
      } catch (error) {
        this.success = false;
        if (error.response?.data?.errorCode === "EMAIL_NOT_FOUND") {
          this.errorMessage =
            "We could not find an account with that email address. Please try a different email address, or contact support.";
        } else {
          this.errorMessage =
            "There was a problem resetting your password. Please check your email and try again, or contact support.";
        }
      }
    },
  },
};
</script>

<template>
  <div class="box">
    <b-alert v-if="errorMessage" variant="danger" show>
      {{ errorMessage }}
    </b-alert>
    <b-alert v-if="success" variant="success" show>
      <b>Email sent</b>
      <p>
        A password reset link was sent to your email address. Please click the
        link in your email to continue.
      </p>
    </b-alert>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="email-group"
        label="Email Address"
        label-for="email-input"
      >
        <b-form-input
          id="email-input"
          v-model="email"
          type="email"
          placeholder="Enter email address"
          required
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="info" class="login-btn">
        Send Password Reset Link
      </b-button>
    </b-form>
  </div>
</template>
