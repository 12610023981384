<script>
import UsageCounters from "../components/dashboard/usage_counters.vue";
import EssaysTable from "../components/essay/essays_table.vue";
import MoveEssayModal from "../components/essay/modals/move_essay_modal.vue";
import EmptyState from "../components/global/empty_state.vue";
import fetchEssays from "@/mixins/fetchEssays";
import { deleteEssay } from "../api";
import Visibility from "visibilityjs";
import ExportToClassroomModal from "@/components/essay/modals/export_to_classroom.vue";

export default {
  name: "EssaysView",
  components: {
    EmptyState,
    UsageCounters,
    EssaysTable,
    MoveEssayModal,
    ExportToClassroomModal,
  },
  mixins: [fetchEssays],
  data() {
    return {
      loading: true,
      error: "",
      currentPage: 1,
      polling: null,
      essayToMoveId: null,
      essayToDeleteId: null,
      reviewedCount: 0,
      gradedCount: 0,
      essayToExport: null,
    };
  },

  computed: {
    show() {
      return this.essays.length > 0 || this.isFiltered || this.tableLoading;
    },
  },

  methods: {
    async deleteSingleEssay(essayId) {
      try {
        this.loading = true;

        await deleteEssay(essayId);

        this.showDeleteSuccess();

        this.fetchEssays();
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    showMoveEssayModal(essayId) {
      this.essayToMoveId = essayId;
      this.$bvModal.show("move-essay-modal");
    },

    showExportToClassroomModal(essay) {
      this.essayToExport = essay;
      this.$bvModal.show("export-to-classroom");
    },

    setMoveSuccess() {
      this.fetchEssays();
      this.showMoveSuccess();
    },
    setMoveError(error) {
      this.error = error;
    },
    startPolling() {
      this.polling = setInterval(() => this.fetchEssays(), 30000);
    },
    stopPolling() {
      clearInterval(this.polling);
    },
    showMoveSuccess() {
      this.$bvToast.toast(
        `The essay was moved to the new class successfully.`,
        {
          title: "Essay moved",
          variant: "success",
          autoHideDelay: 3000,
          solid: true,
        }
      );
    },
    showDeleteSuccess() {
      this.$bvToast.toast(`The essay was deleted successfully.`, {
        title: "Essay deleted",
        variant: "success",
        autoHideDelay: 3000,
        solid: true,
      });
    },
  },
  watch: {
    currentPage() {
      // if you start paginating polling stops, since new data
      // will always be on the first page
      this.stopPolling();
    },
  },
  beforeMount() {
    // intial essays fetch to fill data
    this.fetchEssays().then(() => (this.loading = false));

    // fetch essays every 30 seconds after intial fetch
    this.startPolling();

    // makes sure when browser tab is not active, polling does not continue
    Visibility.change(() => {
      this.stopPolling();
    });
  },
  mounted() {
    // user was re-routed after deleting an essay
    if (this.$route.query.deleted) {
      this.showDeleteSuccess();
    }

    if (this.$route.query.moved) {
      this.showMoveSuccess();
    }
  },
  beforeDestroy() {
    // when component is destroyed, ensure we don't keep polling the
    // endpoint every 30 seconds
    this.stopPolling();
  },
};
</script>

<template>
  <b-container>
    <div v-if="show && !loading" class="text-right svg-container">
      <img src="../assets/illustrations/essays-illustration.svg" alt="" />
    </div>
    <section>
      <template v-if="show">
        <b-row>
          <b-col cols="12" md="6">
            <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
            <div class="d-block d-md-flex align-items-center mb-2">
              <h1 class="mb-0">Essays</h1>
              <span class="ml-md-3 light-grey-text text-date">
                Essays update automatically every 30 seconds. If using Advanced
                Rubrics, it might take a bit longer.
              </span>
            </div>
          </b-col>
          <b-col class="text-right mt-1">
            <b-button variant="info" to="/">Grade new essay</b-button>
          </b-col>
        </b-row>
        <b-row class="mb-3 mt-2">
          <b-col>
            <usage-counters />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <essays-table
              :essays="essays"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :tableLoading="tableLoading"
              @move="showMoveEssayModal"
              @delete="deleteSingleEssay"
              @getData="fetchEssays"
              @export="showExportToClassroomModal"
            />
          </b-col>
        </b-row>
        <b-row v-if="essays.length !== 0">
          <b-col>
            <div class="d-flex justify-content-center">
              <b-pagination
                v-model="currentPage"
                :total-rows="count"
                :per-page="limit"
                @change="fetchEssays"
              >
              </b-pagination>
            </div>
          </b-col>
        </b-row>
        <export-to-classroom-modal
          :essay="essayToExport"
          @hide="essayToExport = null"
        />
      </template>
      <empty-state
        v-else
        title="No essays have been graded yet."
        description="Use AI to grade your essays. Depending on the complexity of the
            text, essay grade times will vary. Happy grading!"
        button-text="Grade new essay"
        button-path="/"
      >
        <img
          src="../assets/illustrations/empty-state-essays.svg"
          alt="EssayGrader Empty State"
        />
      </empty-state>
    </section>

    <move-essay-modal
      :essayId="essayToMoveId"
      @essayMoved="setMoveSuccess"
      @moveError="setMoveError"
    />
  </b-container>
</template>
