<script>
import { resetPasswordV2 } from "../../api";

export default {
  name: "ResetPasswordV2Form",
  data() {
    return {
      newPassword: "",
      error: false,
      success: false,
    };
  },
  methods: {
    async onSubmit() {
      const resetPasswordData = {
        email: this.$route.query.email,
        resetPasswordToken: this.$route.query.token,
        newPassword: this.newPassword,
      };

      try {
        await resetPasswordV2(resetPasswordData);

        this.success = true;
        this.error = false;
      } catch (error) {
        this.success = false;
        this.error = true;
      }
    },
  },
};
</script>

<template>
  <div class="box">
    <b-alert v-if="error" variant="danger" show>
      <p>There was a problem resetting your password.</p>
    </b-alert>
    <b-alert v-if="success" variant="success" show>
      <b>Password reset</b>
      <p>Your password was reset successfully.</p>
      <p>
        <a href="/login">Login with your new password</a>
      </p>
    </b-alert>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="password-group"
        label="Enter a new password below."
        label-for="password-input"
      >
        <b-form-input
          id="password-input"
          v-model="newPassword"
          type="password"
          placeholder="Enter password"
          required
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="info" class="login-btn">
        Reset Password
      </b-button>
    </b-form>
  </div>
</template>
