<template>
  <div>
    <essay-language-select
      :value="selectedLanguageId"
      @input="$emit('input-language', $event)"
    />

    <rubric-intensity-radio-group
      v-if="showIntensity && selectedRubric?.type === 'Advanced'"
      horizontal
      :value="rubricIntensity"
      @input="$emit('input-intensity', $event)"
    />

    <additional-assignment
      v-if="selectedRubric?.type === 'Advanced'"
      @input="$emit('input-tda', $event)"
    />
  </div>
</template>

<script>
import AdditionalAssignment from "@/components/dashboard/additional_assignment.vue";
import EssayLanguageSelect from "@/components/dashboard/essay_language_select.vue";
import RubricIntensityRadioGroup from "@/components/rubrics/rubric_intensity_radio_group.vue";

export default {
  components: {
    AdditionalAssignment,
    EssayLanguageSelect,
    RubricIntensityRadioGroup,
  },

  props: {
    selectedLanguageId: {
      type: Number,
    },

    selectedRubric: {
      type: Object,
    },

    showIntensity: {
      type: Boolean,
      default: false,
    },

    rubricIntensity: {
      type: String,
    },
  },
};
</script>
