<script>
import AiDectectorForm from "@/components/ai_detector/ai_detector_form.vue";
import { getAiDetectionReport } from "../api";
import formatEssay from "../utils/formatEssay";

const WRITTEN_BY_HUMAN = "HUMAN_ONLY";
const WRITTEN_BY_MIXED = "MIXED";
const WRITTEN_BY_AI = "AI_ONLY";

export default {
  name: "AiDetectorView",
  components: { AiDectectorForm },
  data() {
    return {
      loading: false,
      error: "",
      stats: null,
      essay: null,
    };
  },
  computed: {
    probabilityPrecentage() {
      return Math.round(this.stats.completely_generated_prob * 100);
    },
    totalSentences() {
      return this.stats?.sentences.length || 0;
    },
    totalSentencesAi() {
      return this.sentencesWrittenByAi.length;
    },
    sentencesWrittenByAi() {
      const sentencesArr = this.stats?.sentences || [];

      const filtered = sentencesArr.filter((sentence) => {
        if (sentence.highlight_sentence_for_ai === true) {
          return sentence;
        }
      });

      return filtered;
    },
    burstinessScore() {
      return this.stats?.overall_burstiness.toFixed(3);
    },
    outcome() {
      return this.stats?.result_message || "";
    },
    iconHandler() {
      let icon;

      switch (this.stats?.document_classification) {
        case WRITTEN_BY_HUMAN:
          icon = "person-check";
          break;
        case WRITTEN_BY_AI:
          icon = "person-x";
          break;
        case WRITTEN_BY_MIXED:
          icon = "person-exclamation";
          break;
        default:
          break;
      }

      return icon;
    },
  },
  methods: {
    async detect(payload) {
      try {
        this.loading = true;

        const { data } = await getAiDetectionReport(payload);

        this.stats = data;
        this.essay = formatEssay(data.sentences, data.paragraphs);

        this.loading = false;
        this.error = "";
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    print() {
      window.print();
    },
  },

  created() {
    const essayContents = this.$store.state.aiDetectorEssayContent;

    if (essayContents) {
      this.detect({ essayContents });
    }
  },
};
</script>

<template>
  <b-container>
    <div v-if="!stats && !loading" class="text-right svg-container">
      <img src="../assets/illustrations/detector-illustration.svg" alt="" />
    </div>
    <section>
      <b-row v-if="loading">
        <b-col md="3"></b-col>
        <b-col md="6" class="text-center">
          <b-spinner class="mb-4" style="width: 3rem; height: 3rem"></b-spinner>
          <h4>Evaluating essay...</h4>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <template v-if="!stats">
            <h1 class="mb-4">AI Detector</h1>
          </template>
        </b-col>

        <b-col>
          <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
          <div v-if="!stats">
            <p class="mb-2">
              Upload an essay to see if it was generated by AI.
            </p>
            <ai-dectector-form @essaySubmitted="detect" />
          </div>

          <div v-else>
            <div class="d-flex justify-content-between mb-3">
              <h1>Detection results</h1>
              <b-button @click="print">Print results</b-button>
            </div>

            <b-card class="mb-4">
              <template #header></template>
              <h5 class="mb-3">
                <b-icon :icon="iconHandler" class="icon-purple mr-2"></b-icon>
                <span>Outcome: {{ outcome }}</span>
              </h5>
              <div class="light-grey-text">
                <p class="mb-0 font-rubik">Detection summary:</p>
                <p class="mb-0">
                  There is a
                  <span class="text-primary">{{ probabilityPrecentage }}%</span>
                  chance this text was entirely written by AI.
                </p>
                <p class="mb-0 light-grey-text">
                  <span class="text-primary">
                    {{ `${totalSentencesAi}/${totalSentences}` }}
                  </span>
                  sentences were likely written by AI.
                </p>
              </div>
            </b-card>

            <div>
              <h6>Statistics</h6>

              <p>
                <span>Burstiness Score:</span>
                {{ burstinessScore }}
              </p>
              <b-progress
                :value="stats.overall_burstiness"
                variant="info"
                class="mb-3"
                max="2000"
              ></b-progress>
              <p>
                The amount of variation in the perplexity of the document. A
                useful indicator to distinguish AI and human written text
              </p>
              <hr />
              <h3>Detection breakdown</h3>

              <div class="mb-3">
                <p class="mb-3">Sentences likely to have been written by AI.</p>
                <ol v-if="totalSentencesAi > 0">
                  <li
                    v-for="(obj, index) in sentencesWrittenByAi"
                    :key="index"
                    class="mb-2"
                  >
                    <span class="highlight">"{{ obj.sentence }}"</span>
                  </li>
                </ol>
                <span class="d-block" v-else
                  >None, no sentences detected to have been written by AI.</span
                >
              </div>
              <div class="mb-5">
                <b-button v-b-toggle.collapse-1 block class="text-left">
                  Toggle essay (sentences likely written by AI are
                  <span class="highlight">highlighted</span>)
                </b-button>
                <b-collapse id="collapse-1" class="mt-2">
                  <div class="ai-detection-essay" v-html="essay"></div>
                </b-collapse>
              </div>
            </div>
          </div>
        </b-col>
        <b-col v-if="!stats" cols="1"></b-col>
        <b-col v-if="!stats" cols="6">
          <div class="help-box">
            <p class="font-rubik">How does it work?</p>
            <ul>
              <li>
                Detects the natural variability and randomness in the text
              </li>
              <li>Evaluates the complexity and subtlety of the language</li>
              <li>Analyzes the regular and repetitive patterns in the text</li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
