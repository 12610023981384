import api from "@/api/api";

export const createRubric = (payload) => {
  return api.post("/rubrics", payload);
};

export const getRubrics = () => {
  return api.get("/rubrics");
};

export const getSingleRubric = (rubricId) => {
  return api.get(`/rubrics/${rubricId}`);
};

export const updateSingleRubric = (rubricId, payload) => {
  return api.put(`/rubrics/${rubricId}`, payload);
};

export const deleteSingleRubric = (rubricId) => {
  return api.delete(`/rubrics/${rubricId}`);
};
