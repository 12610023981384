<script>
import growthbookClient from "@/mixins/growthbook_client";

export default {
  data() {
    return {
      maintenanceMessage: null,
    };
  },
  computed: {
    show() {
      return !!this.maintenanceMessage;
    },
  },

  async mounted() {
    await growthbookClient.init({ streaming: true });
    this.maintenanceMessage = growthbookClient.getFeatureValue(
      "maintenance-message"
    );
  },
};
</script>

<template>
  <b-alert class="mb-0" variant="warning" dismissible :show="show">
    <p class="mb-0 text-center">
      {{ maintenanceMessage }}
    </p>
  </b-alert>
</template>
