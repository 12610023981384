<script>
export default {
  name: "EssayStatsCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    headerColor: {
      type: String,
      required: false,
      default: "",
    },
    iconClass: {
      type: String,
      required: false,
    },
    iconScale: {
      type: String,
      required: false,
    },
  },
};
</script>

<template>
  <b-card class="essay-stats-card" :class="headerColor">
    <template #header></template>
    <div class="light-grey-text">{{ title }}</div>
    <div>
      <span class="count mr-2">{{ count }}</span>
      <b-icon
        v-if="icon"
        :class="iconClass"
        :icon="icon"
        :scale="iconScale"
      ></b-icon>
    </div>
  </b-card>
</template>
