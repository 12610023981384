<template>
  <b-form-group>
    <template #label>
      <div>
        Grading intensity <span class="red-text">*</span>
        <p class="text-muted small mb-0">
          The level that AI will grade essays at using this rubric. The
          difficulty level can be overridden when grading essays, if necessary.
        </p>
      </div>
    </template>
    <div :class="horizontal ? 'd-flex' : 'd-block'">
      <b-form-radio
        v-for="radioOption in radioOptions"
        :key="radioOption.value"
        :class="horizontal && 'mr-3'"
        :checked="value"
        :value="radioOption.value"
        name="rubric-intensity-selector"
        @change="$emit('input', $event)"
      >
        {{ radioOption.label }}
      </b-form-radio>
    </div>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "moderate",
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      radioOptions: [
        { value: "light", label: "Easy" },
        { value: "moderate", label: "Normal" },
        { value: "intense", label: "Strict" },
      ],
    };
  },
};
</script>
import { change } from 'visibilityjs';
