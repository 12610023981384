export const BASIC_PLAN = "basic";
export const LITE_PLAN = "lite";
export const PRO_PLAN = "pro";
export const PREMIUM_PLAN = "premium";

export const CLASSROOM_SCOPE = [
  "https://www.googleapis.com/auth/classroom.courses.readonly",
  "https://www.googleapis.com/auth/classroom.coursework.students",
  "https://www.googleapis.com/auth/classroom.rosters.readonly",
  "https://www.googleapis.com/auth/documents",
];

export const LOCAL_STORAGE_FEATURE_KEY = "essaygrader-version";
