<template>
  <b-alert
    v-if="user"
    class="mb-0"
    variant="warning"
    :show="show"
    dismissible
    @dismissed="dismiss"
  >
    <p class="mb-0 text-center">
      Your plan is currently paused and your access to EssayGrader is limited to
      the basic plan. <a href="#" @click.prevent="unpause">Click here</a>
      to unpause your plan and access all the benefits you know and love.
    </p>
  </b-alert>
</template>

<script>
import { unpause } from "../../api";

export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    isPaused() {
      return this.$store.getters.isPaused;
    },
    storageKey() {
      return this.$store.getters.pausedStorageKey;
    },
    show() {
      return this.isPaused && localStorage.getItem(this.storageKey) !== "true";
    },
  },

  methods: {
    dismiss() {
      if (this.isPaused) {
        localStorage.setItem(this.storageKey, "true");
      }
    },

    unpause() {
      return unpause(this);
    },
  },
};
</script>
