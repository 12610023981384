export default (sentences, paragraphs) => {
  const highlightedSentences = [];

  sentences.forEach((obj) => {
    if (obj.highlight_sentence_for_ai) {
      highlightedSentences.push(
        `<span class='highlight'>${obj.sentence}</span>`
      );
    } else {
      highlightedSentences.push(obj.sentence);
    }
  });

  const result = [];

  paragraphs.forEach((obj) => {
    const sentenceInParagraph = [];

    const start = obj.start_sentence_index;
    const end = start + obj.num_sentences - 1;

    for (let i = start; i <= end; i++) {
      sentenceInParagraph.push(highlightedSentences[i]);
    }

    const joined = sentenceInParagraph.join(" ");

    result.push(`<p>${joined}</p>`);
  });

  return result.join(" ");
};
